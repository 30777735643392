import { useEffect } from 'react';
import _ from 'underscore';

// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { Dialog } from 'app/scripts/views/lib/Dialog';

const debouncedOnResize = _.debounce(function () {
  if (Dialog.isVisible) {
    Dialog.calcPos('');
  }
}, 300);

export const useDialogPositioner = () => {
  useEffect(() => {
    $(window).on('resize.windowEvent', debouncedOnResize);

    return () => {
      $(window).off('resize.windowEvent', debouncedOnResize);
    };
  }, []);
};
