// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.luWkS88PBCKf6a {
  width: 100%;
  margin-bottom: 16px;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
table.luWkS88PBCKf6a,
table.luWkS88PBCKf6a td {
  border: none;
  vertical-align: top;
}
table.luWkS88PBCKf6a tbody {
  background-color: transparent;
}
table.luWkS88PBCKf6a tbody td {
  padding: 0 0 15px 0;
}
table.luWkS88PBCKf6a td:nth-child(2) {
  width: 200px;
  text-align: right;
}
table.luWkS88PBCKf6a tfoot {
  border-top: 1px solid var(--ds-border, #091e4224);
  font-size: 14px;
}
table.luWkS88PBCKf6a tfoot td {
  padding: 10px 0 0 0;
  font-size: 14px;
  font-weight: 600;
}
.ZGA3ERF6myHaj7 {
  color: var(--ds-text-subtle, #44546f);
  font-size: 12px;
}
.oYuImd54qQhCEt {
  text-decoration: line-through;
}
.TVpUBqQ7oCatGY {
  color: var(--ds-text-success, #216e4e);
}
.YdPdKr9RTdAEIJ {
  font-size: 12px;
}
.rg9yp9Zj5DQitu {
  white-space: nowrap;
}
.PVjB5JW3Hql1PT {
  color: var(--ds-text-danger, #ae2a19);
  font-weight: 700;
}
.isauXG4lauEoG2 {
  font-weight: 600;
}
.ga4jX7SQEtJtee {
  font-size: 11px;
  text-align: right;
}
.RCknXaFJa8e_Wl {
  font-size: 14px;
}
.RCknXaFJa8e_Wl .lMTKEc9BHqpVYQ {
  font-weight: 600;
}
.RCknXaFJa8e_Wl .ABS_Zx_NcGQBga {
  display: inline-block;
  width: 100px;
  color: var(--ds-text-information, #0055cc);
  font-size: 12px;
}
.RCknXaFJa8e_Wl .ZGA3ERF6myHaj7 {
  margin-top: 15px;
}
.RCknXaFJa8e_Wl .ZGA3ERF6myHaj7 li {
  margin-left: 16px;
  list-style-type: disc;
  font-size: 12px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary": `luWkS88PBCKf6a`,
	"multiBoardGuestLineItem": `ZGA3ERF6myHaj7`,
	"strikeThrough": `oYuImd54qQhCEt`,
	"discountedPrice": `TVpUBqQ7oCatGY`,
	"discountApplied": `YdPdKr9RTdAEIJ`,
	"pendingInvitationPrice": `rg9yp9Zj5DQitu`,
	"errorMessage": `PVjB5JW3Hql1PT`,
	"salesTax": `isauXG4lauEoG2`,
	"nextBillDate": `ga4jX7SQEtJtee`,
	"nuskuSummaryTable": `RCknXaFJa8e_Wl`,
	"nuskuLicenseDescription": `lMTKEc9BHqpVYQ`,
	"nuskuSavePercentage": `ABS_Zx_NcGQBga`
};
export default ___CSS_LOADER_EXPORT___;
