import {
  deleteCookie,
  getCookie,
  setStrictlyNecessaryCookie,
} from '@atlassian/browser-storage-controls';

const cookieName = 'aaId';

export let aaId: string | null;

export const getAaIdFromCookie = (): string | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  return getCookie(cookieName) || null;
};

export const getAaId = (): string | null => {
  return aaId || null;
};

export const setAaId = (id: string | null): void => {
  aaId = id;

  if (id) {
    setStrictlyNecessaryCookie(cookieName, id);
  } else {
    deleteCookie(cookieName);
  }
};

// eslint-disable-next-line @trello/no-module-logic
aaId = getAaIdFromCookie();
