// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EFnmobNHcuew1E {
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr 1fr;
  grid-template-rows: auto;
  align-items: baseline;
  column-gap: 16px;
  justify-items: stretch;
}
.EFnmobNHcuew1E.zo927y9lWziiUy {
  grid-template-areas: 'number number number number' 'expiration expiration expiration expiration' 'cvv cvv cvv cvv' 'country country country country' 'zip zip zip zip' 'taxid taxid taxid taxid' 'statetaxid statetaxid statetaxid statetaxid';
}
.EFnmobNHcuew1E.bpoqc9dSGOOkox {
  grid-template-areas: 'number number number number' 'expiration expiration cvv cvv' 'country country country country' 'zip zip zip zip' 'taxid taxid taxid taxid' 'statetaxid statetaxid statetaxid statetaxid';
}
.EFnmobNHcuew1E.iR77lolzjDpEJG {
  grid-template-areas: 'number number number number' 'expiration cvv cvv .' 'country country zip zip' 'taxid taxid taxid taxid' 'statetaxid statetaxid statetaxid statetaxid';
}
.EFnmobNHcuew1E.o9UFHz8M4N1_lH {
  grid-template-areas: 'number number number number' 'expiration cvv cvv .' 'country country zip zip' 'taxid taxid statetaxid statetaxid';
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `EFnmobNHcuew1E`,
	"xsmall": `zo927y9lWziiUy`,
	"small": `bpoqc9dSGOOkox`,
	"medium": `iR77lolzjDpEJG`,
	"large": `o9UFHz8M4N1_lH`
};
export default ___CSS_LOADER_EXPORT___;
