// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IhOp6hdgoCyaah {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.IhOp6hdgoCyaah > .header-banner,
.IhOp6hdgoCyaah > #header {
  flex: none;
}
.IhOp6hdgoCyaah #content {
  flex-grow: 1;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"surface": `IhOp6hdgoCyaah`
};
export default ___CSS_LOADER_EXPORT___;
