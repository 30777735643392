import type { Product } from '../types';
import {
  ProductFamily,
  ProductInterval,
  ProductName,
  ProductShortName,
} from '../types';
import { Products } from './ids';

export const enterpriseProducts: Product[] = [
  {
    id: Products.Enterprise.v1,
    shortName: ProductShortName.Enterprise,
    name: ProductName.Enterprise,
    interval: ProductInterval.Yearly,
    family: ProductFamily.Enterprise,
    perUser: false,
    prebill: true,
    current: true,
    hidden: true,
    purchaseOrder: true,
  },
  {
    id: Products.Enterprise.v1_1,
    shortName: ProductShortName.Enterprise1x1TieredPricing,
    name: ProductName.Enterprise1x1,
    interval: ProductInterval.Yearly,
    family: ProductFamily.Enterprise,
    perUser: false,
    prebill: true,
    current: true,
    hidden: true,
    purchaseOrder: true,
  },
  {
    id: Products.Enterprise.v1_2,
    shortName: ProductShortName.Enterprise1x2SiteLicense,
    name: ProductName.Enterprise1x2,
    interval: ProductInterval.Yearly,
    family: ProductFamily.Enterprise,
    perUser: false,
    prebill: true,
    current: true,
    hidden: true,
    purchaseOrder: true,
  },
  {
    id: Products.PremiumPO.v3_3,
    shortName: ProductShortName.Premium,
    name: ProductName.Premium,
    interval: ProductInterval.Yearly,
    family: ProductFamily.Premium,
    perUser: false,
    prebill: true,
    current: true,
    hidden: true,
    purchaseOrder: true,
  },
  {
    id: Products.Enterprise.v2_0,
    shortName: ProductShortName.Enterprise2x0TieredPricing,
    name: ProductName.Enterprise2x0,
    interval: ProductInterval.Yearly,
    family: ProductFamily.Enterprise,
    perUser: false,
    prebill: true,
    current: true,
    hidden: true,
    purchaseOrder: true,
  },
  {
    id: Products.Enterprise.v2_1,
    shortName: ProductShortName.Enterprise2x1SiteLicense,
    name: ProductName.Enterprise2x1,
    interval: ProductInterval.Yearly,
    family: ProductFamily.Enterprise,
    perUser: false,
    prebill: true,
    hidden: true,
    current: true,
    purchaseOrder: true,
  },
  {
    id: Products.Enterprise.v2_2,
    shortName: ProductShortName.Enterprise2x2Together,
    name: ProductName.Enterprise2x2,
    interval: ProductInterval.Yearly,
    family: ProductFamily.Enterprise,
    perUser: false,
    prebill: true,
    hidden: true,
    current: true,
    purchaseOrder: true,
  },
];
