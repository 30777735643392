import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { useLazyComponent } from '@trello/use-lazy-component';

import { WorkspaceNavigationError } from './WorkspaceNavigationError';

export const LazyWorkspaceNavigation: FunctionComponent<object> = () => {
  const WorkspaceNavigation = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "workspace-navigation" */ './WorkspaceNavigation'
      ),
    { namedImport: 'WorkspaceNavigation' },
  );

  return (
    <ErrorBoundary
      tags={{
        ownershipArea: 'trello-web-eng',
        feature: 'Workspace Navigation',
      }}
      errorHandlerComponent={WorkspaceNavigationError}
    >
      <Suspense fallback={null}>
        <ChunkLoadErrorBoundary fallback={<WorkspaceNavigationError />}>
          <WorkspaceNavigation />
        </ChunkLoadErrorBoundary>
      </Suspense>
    </ErrorBoundary>
  );
};
