import type { MouseEvent as ReactMouseEvent } from 'react';

import { l } from 'app/scripts/lib/localize';
import { PopOver } from 'app/scripts/views/lib/PopOver';
import { FileLinkComponent } from 'app/src/components/FileLinkComponent';

export const fileLinkHandler = (
  event: MouseEvent | ReactMouseEvent,
  linkElem: HTMLAnchorElement,
) => {
  event.preventDefault();
  PopOver.show({
    elem: linkElem,
    getViewTitle() {
      return l(['view title', 'open file link']);
    },
    reactElement: (
      <FileLinkComponent key="FileLinkComponent" url={linkElem.href} />
    ),
  });
};
