// eslint-disable-next-line no-restricted-modules
const $ = require('jquery');
const { addAutosizePlugin } = require('./jquery.autosize');
const { throttleOnAnimationFrame } = require('@trello/time');

$.extend({ throttleOnAnimationFrame });

addAutosizePlugin($);

window.jQuery = $;
window.$ = $;

// Apparently those stupid files don't close over the jQuery variable.
// So we can't delete it after we're done.
// delete window.jQuery;
// delete window.$;

module.exports = $;
