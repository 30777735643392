// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bxgKMAm3lq5BpA {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-display: swap;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 3px;
  text-decoration: none;
  white-space: normal;
  cursor: pointer;
  border: none;
  background-color: var(--ds-background-neutral, #091e420f);
  box-shadow: none;
  color: var(--ds-text, #172b4d);
  font-weight: 500;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
}
.bxgKMAm3lq5BpA .gNDonYrNSA1GXJ [role='img'] > svg,
.bxgKMAm3lq5BpA .Lm69QtwCvEGJyG [role='img'] > svg,
.bxgKMAm3lq5BpA .fAvkXZrzkeHLoc [role='img'] > svg,
.bxgKMAm3lq5BpA .gNDonYrNSA1GXJ svg[role='presentation'],
.bxgKMAm3lq5BpA .Lm69QtwCvEGJyG svg[role='presentation'],
.bxgKMAm3lq5BpA .fAvkXZrzkeHLoc svg[role='presentation'] {
  color: unset !important;
}
.bxgKMAm3lq5BpA .gNDonYrNSA1GXJ {
  flex-shrink: 0;
  margin-right: 8px;
}
.bxgKMAm3lq5BpA .Lm69QtwCvEGJyG {
  flex-shrink: 0;
  margin-left: 4px;
}
.HAVwIqCeMHpVKh {
  padding: 6px;
}
.bxgKMAm3lq5BpA:hover {
  border: none;
  background-color: var(--ds-background-neutral-hovered, #091e4224);
  box-shadow: none;
  color: var(--ds-text, #172b4d);
  text-decoration: none;
}
.bxgKMAm3lq5BpA:active {
  border: none;
  outline: 0;
  background-color: var(--ds-background-neutral-pressed, #091e424f);
  box-shadow: none;
  color: var(--ds-text, #172b4d);
}
.bxgKMAm3lq5BpA:active .Sg9WearZzOmo7n {
  color: var(--ds-text, #172b4d);
}
.bxgKMAm3lq5BpA:active svg circle {
  stroke: var(--ds-text, #172b4d);
}
.bxgKMAm3lq5BpA:focus-visible {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .bxgKMAm3lq5BpA:focus-visible {
  outline-color: -webkit-focus-ring-color;
}
.bxgKMAm3lq5BpA:focus-visible .mac.firefox {
  outline-color: AccentColor;
}
.SEj5vUdI3VvxDc:focus {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .SEj5vUdI3VvxDc:focus {
  outline-color: -webkit-focus-ring-color;
}
.SEj5vUdI3VvxDc:focus .mac.firefox {
  outline-color: AccentColor;
}
.SEj5vUdI3VvxDc:focus:not(.y49lYPyzBoLRYa) {
  outline: 0;
}
.ec7Rkt8YVvz6Rg,
.bxgKMAm3lq5BpA:disabled,
.ec7Rkt8YVvz6Rg:hover,
.bxgKMAm3lq5BpA:disabled:hover,
.ec7Rkt8YVvz6Rg:active,
.bxgKMAm3lq5BpA:disabled:active {
  border: none;
  background-color: var(--ds-background-disabled, #091e4208);
  box-shadow: none;
  color: var(--ds-text-disabled, #091e424f);
  cursor: not-allowed;
}
.ec7Rkt8YVvz6Rg .gNDonYrNSA1GXJ [role='img'] > svg,
.bxgKMAm3lq5BpA:disabled .gNDonYrNSA1GXJ [role='img'] > svg,
.ec7Rkt8YVvz6Rg:hover .gNDonYrNSA1GXJ [role='img'] > svg,
.bxgKMAm3lq5BpA:disabled:hover .gNDonYrNSA1GXJ [role='img'] > svg,
.ec7Rkt8YVvz6Rg:active .gNDonYrNSA1GXJ [role='img'] > svg,
.bxgKMAm3lq5BpA:disabled:active .gNDonYrNSA1GXJ [role='img'] > svg,
.ec7Rkt8YVvz6Rg .Lm69QtwCvEGJyG [role='img'] > svg,
.bxgKMAm3lq5BpA:disabled .Lm69QtwCvEGJyG [role='img'] > svg,
.ec7Rkt8YVvz6Rg:hover .Lm69QtwCvEGJyG [role='img'] > svg,
.bxgKMAm3lq5BpA:disabled:hover .Lm69QtwCvEGJyG [role='img'] > svg,
.ec7Rkt8YVvz6Rg:active .Lm69QtwCvEGJyG [role='img'] > svg,
.bxgKMAm3lq5BpA:disabled:active .Lm69QtwCvEGJyG [role='img'] > svg,
.ec7Rkt8YVvz6Rg .fAvkXZrzkeHLoc [role='img'] > svg,
.bxgKMAm3lq5BpA:disabled .fAvkXZrzkeHLoc [role='img'] > svg,
.ec7Rkt8YVvz6Rg:hover .fAvkXZrzkeHLoc [role='img'] > svg,
.bxgKMAm3lq5BpA:disabled:hover .fAvkXZrzkeHLoc [role='img'] > svg,
.ec7Rkt8YVvz6Rg:active .fAvkXZrzkeHLoc [role='img'] > svg,
.bxgKMAm3lq5BpA:disabled:active .fAvkXZrzkeHLoc [role='img'] > svg,
.ec7Rkt8YVvz6Rg .gNDonYrNSA1GXJ svg[role='presentation'],
.bxgKMAm3lq5BpA:disabled .gNDonYrNSA1GXJ svg[role='presentation'],
.ec7Rkt8YVvz6Rg:hover .gNDonYrNSA1GXJ svg[role='presentation'],
.bxgKMAm3lq5BpA:disabled:hover .gNDonYrNSA1GXJ svg[role='presentation'],
.ec7Rkt8YVvz6Rg:active .gNDonYrNSA1GXJ svg[role='presentation'],
.bxgKMAm3lq5BpA:disabled:active .gNDonYrNSA1GXJ svg[role='presentation'],
.ec7Rkt8YVvz6Rg .Lm69QtwCvEGJyG svg[role='presentation'],
.bxgKMAm3lq5BpA:disabled .Lm69QtwCvEGJyG svg[role='presentation'],
.ec7Rkt8YVvz6Rg:hover .Lm69QtwCvEGJyG svg[role='presentation'],
.bxgKMAm3lq5BpA:disabled:hover .Lm69QtwCvEGJyG svg[role='presentation'],
.ec7Rkt8YVvz6Rg:active .Lm69QtwCvEGJyG svg[role='presentation'],
.bxgKMAm3lq5BpA:disabled:active .Lm69QtwCvEGJyG svg[role='presentation'],
.ec7Rkt8YVvz6Rg .fAvkXZrzkeHLoc svg[role='presentation'],
.bxgKMAm3lq5BpA:disabled .fAvkXZrzkeHLoc svg[role='presentation'],
.ec7Rkt8YVvz6Rg:hover .fAvkXZrzkeHLoc svg[role='presentation'],
.bxgKMAm3lq5BpA:disabled:hover .fAvkXZrzkeHLoc svg[role='presentation'],
.ec7Rkt8YVvz6Rg:active .fAvkXZrzkeHLoc svg[role='presentation'],
.bxgKMAm3lq5BpA:disabled:active .fAvkXZrzkeHLoc svg[role='presentation'] {
  color: unset !important;
}
.ec7Rkt8YVvz6Rg svg circle,
.bxgKMAm3lq5BpA:disabled svg circle,
.ec7Rkt8YVvz6Rg:hover svg circle,
.bxgKMAm3lq5BpA:disabled:hover svg circle,
.ec7Rkt8YVvz6Rg:active svg circle,
.bxgKMAm3lq5BpA:disabled:active svg circle {
  stroke: var(--ds-text-disabled, #091e424f);
}
.SdamsUKjxSBwGb {
  border: none;
  background-color: var(--ds-background-brand-bold, #0c66e4);
  box-shadow: none;
  color: var(--ds-text-inverse, #ffffff);
}
.SdamsUKjxSBwGb .gNDonYrNSA1GXJ [role='img'] > svg,
.SdamsUKjxSBwGb .Lm69QtwCvEGJyG [role='img'] > svg,
.SdamsUKjxSBwGb .fAvkXZrzkeHLoc [role='img'] > svg,
.SdamsUKjxSBwGb .gNDonYrNSA1GXJ svg[role='presentation'],
.SdamsUKjxSBwGb .Lm69QtwCvEGJyG svg[role='presentation'],
.SdamsUKjxSBwGb .fAvkXZrzkeHLoc svg[role='presentation'] {
  color: unset !important;
}
.SdamsUKjxSBwGb:hover {
  border: none;
  background-color: var(--ds-background-brand-bold-hovered, #0055cc);
  box-shadow: none;
  color: var(--ds-text-inverse, #ffffff);
  text-decoration: none;
}
.SdamsUKjxSBwGb:hover svg circle {
  stroke: var(--ds-text-inverse, #ffffff);
}
.SdamsUKjxSBwGb:active {
  border: none;
  outline: 0;
  background-color: var(--ds-background-brand-bold-pressed, #09326c);
  box-shadow: none;
  color: var(--ds-text-inverse, #ffffff);
}
.SdamsUKjxSBwGb:active svg circle {
  stroke: var(--ds-text-inverse, #ffffff);
}
.KpU415sFFvOzGZ {
  border: none;
  background-color: var(--ds-background-danger-bold, #ca3521);
  box-shadow: none;
  color: var(--ds-text-inverse, #ffffff);
}
.KpU415sFFvOzGZ .gNDonYrNSA1GXJ [role='img'] > svg,
.KpU415sFFvOzGZ .Lm69QtwCvEGJyG [role='img'] > svg,
.KpU415sFFvOzGZ .fAvkXZrzkeHLoc [role='img'] > svg,
.KpU415sFFvOzGZ .gNDonYrNSA1GXJ svg[role='presentation'],
.KpU415sFFvOzGZ .Lm69QtwCvEGJyG svg[role='presentation'],
.KpU415sFFvOzGZ .fAvkXZrzkeHLoc svg[role='presentation'] {
  color: unset !important;
}
.KpU415sFFvOzGZ svg circle {
  stroke: var(--ds-text-inverse, #ffffff);
}
.KpU415sFFvOzGZ:hover {
  border: none;
  background-color: var(--ds-background-danger-bold-hovered, #ae2a19);
  box-shadow: none;
  color: var(--ds-text-inverse, #ffffff);
  text-decoration: none;
}
.KpU415sFFvOzGZ:hover svg circle {
  stroke: var(--ds-text-inverse, #ffffff);
}
.KpU415sFFvOzGZ:active {
  border: none;
  outline: 0;
  background-color: var(--ds-background-danger-bold-pressed, #601e16);
  box-shadow: none;
  color: var(--ds-text-inverse, #ffffff);
}
.KpU415sFFvOzGZ:active svg circle {
  stroke: var(--ds-text-inverse, #ffffff);
}
.V_9lMAQOdk_AYt {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: var(--ds-link, #0c66e4);
  text-decoration: none;
  padding: 0;
}
.V_9lMAQOdk_AYt .gNDonYrNSA1GXJ [role='img'] > svg,
.V_9lMAQOdk_AYt .Lm69QtwCvEGJyG [role='img'] > svg,
.V_9lMAQOdk_AYt .fAvkXZrzkeHLoc [role='img'] > svg,
.V_9lMAQOdk_AYt .gNDonYrNSA1GXJ svg[role='presentation'],
.V_9lMAQOdk_AYt .Lm69QtwCvEGJyG svg[role='presentation'],
.V_9lMAQOdk_AYt .fAvkXZrzkeHLoc svg[role='presentation'] {
  color: unset !important;
}
.V_9lMAQOdk_AYt svg circle {
  stroke: var(--ds-link, #0c66e4);
}
.V_9lMAQOdk_AYt:hover {
  background-color: transparent;
  color: var(--ds-link, #0c66e4);
  text-decoration: underline;
}
.V_9lMAQOdk_AYt:hover svg circle {
  stroke: var(--ds-link, #0c66e4);
}
.V_9lMAQOdk_AYt:active {
  background-color: transparent;
  color: var(--ds-link-pressed, #0055cc);
}
.V_9lMAQOdk_AYt:active svg circle {
  stroke: var(--ds-link-pressed, #0055cc);
}
.iUcMblFAuq9LKn {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.iUcMblFAuq9LKn .gNDonYrNSA1GXJ [role='img'] > svg,
.iUcMblFAuq9LKn .Lm69QtwCvEGJyG [role='img'] > svg,
.iUcMblFAuq9LKn .fAvkXZrzkeHLoc [role='img'] > svg,
.iUcMblFAuq9LKn .gNDonYrNSA1GXJ svg[role='presentation'],
.iUcMblFAuq9LKn .Lm69QtwCvEGJyG svg[role='presentation'],
.iUcMblFAuq9LKn .fAvkXZrzkeHLoc svg[role='presentation'] {
  color: unset !important;
}
.iUcMblFAuq9LKn:hover {
  border: none;
  background-color: var(--ds-background-neutral-hovered, #091e4224);
  box-shadow: none;
  color: var(--ds-text, #172b4d);
  text-decoration: none;
}
.iUcMblFAuq9LKn:active {
  border: none;
  outline: 0;
  background-color: var(--ds-background-neutral-pressed, #091e424f);
  box-shadow: none;
  color: var(--ds-text, #172b4d);
}
.iUcMblFAuq9LKn:active .Sg9WearZzOmo7n {
  color: var(--ds-text, #172b4d);
}
.iUcMblFAuq9LKn:active svg circle {
  stroke: var(--ds-text, #172b4d);
}
.ctJF4taPJ4WGY0 {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: var(--ds-link, #0c66e4);
  text-decoration: none;
}
.ctJF4taPJ4WGY0 .gNDonYrNSA1GXJ [role='img'] > svg,
.ctJF4taPJ4WGY0 .Lm69QtwCvEGJyG [role='img'] > svg,
.ctJF4taPJ4WGY0 .fAvkXZrzkeHLoc [role='img'] > svg,
.ctJF4taPJ4WGY0 .gNDonYrNSA1GXJ svg[role='presentation'],
.ctJF4taPJ4WGY0 .Lm69QtwCvEGJyG svg[role='presentation'],
.ctJF4taPJ4WGY0 .fAvkXZrzkeHLoc svg[role='presentation'] {
  color: unset !important;
}
.ctJF4taPJ4WGY0 svg circle {
  stroke: var(--ds-link, #0c66e4);
}
.ctJF4taPJ4WGY0:hover {
  background-color: transparent;
  color: var(--ds-link, #0c66e4);
  text-decoration: underline;
}
.ctJF4taPJ4WGY0:hover svg circle {
  stroke: var(--ds-link, #0c66e4);
}
.ctJF4taPJ4WGY0:active {
  border: none;
  outline: 0;
  background-color: var(--ds-background-neutral-pressed, #091e424f);
  box-shadow: none;
  color: var(--ds-text, #172b4d);
}
.ctJF4taPJ4WGY0:active .Sg9WearZzOmo7n {
  color: var(--ds-text, #172b4d);
}
.ctJF4taPJ4WGY0:active svg circle {
  stroke: var(--ds-text, #172b4d);
}
.V_9lMAQOdk_AYt:disabled,
.V_9lMAQOdk_AYt.ec7Rkt8YVvz6Rg,
.iUcMblFAuq9LKn:disabled,
.iUcMblFAuq9LKn.ec7Rkt8YVvz6Rg,
.ctJF4taPJ4WGY0:disabled,
.ctJF4taPJ4WGY0.ec7Rkt8YVvz6Rg,
.V_9lMAQOdk_AYt:disabled:hover,
.V_9lMAQOdk_AYt.ec7Rkt8YVvz6Rg:hover,
.iUcMblFAuq9LKn:disabled:hover,
.iUcMblFAuq9LKn.ec7Rkt8YVvz6Rg:hover,
.ctJF4taPJ4WGY0:disabled:hover,
.ctJF4taPJ4WGY0.ec7Rkt8YVvz6Rg:hover,
.V_9lMAQOdk_AYt:disabled:active,
.V_9lMAQOdk_AYt.ec7Rkt8YVvz6Rg:active,
.iUcMblFAuq9LKn:disabled:active,
.iUcMblFAuq9LKn.ec7Rkt8YVvz6Rg:active,
.ctJF4taPJ4WGY0:disabled:active,
.ctJF4taPJ4WGY0.ec7Rkt8YVvz6Rg:active {
  border: none;
  background-color: var(--ds-background-disabled, #091e4208);
  box-shadow: none;
  color: var(--ds-text-disabled, #091e424f);
  cursor: not-allowed;
  background-color: var(--ds-background-neutral-subtle, #00000000);
}
.V_9lMAQOdk_AYt:disabled svg circle,
.V_9lMAQOdk_AYt.ec7Rkt8YVvz6Rg svg circle,
.iUcMblFAuq9LKn:disabled svg circle,
.iUcMblFAuq9LKn.ec7Rkt8YVvz6Rg svg circle,
.ctJF4taPJ4WGY0:disabled svg circle,
.ctJF4taPJ4WGY0.ec7Rkt8YVvz6Rg svg circle,
.V_9lMAQOdk_AYt:disabled:hover svg circle,
.V_9lMAQOdk_AYt.ec7Rkt8YVvz6Rg:hover svg circle,
.iUcMblFAuq9LKn:disabled:hover svg circle,
.iUcMblFAuq9LKn.ec7Rkt8YVvz6Rg:hover svg circle,
.ctJF4taPJ4WGY0:disabled:hover svg circle,
.ctJF4taPJ4WGY0.ec7Rkt8YVvz6Rg:hover svg circle,
.V_9lMAQOdk_AYt:disabled:active svg circle,
.V_9lMAQOdk_AYt.ec7Rkt8YVvz6Rg:active svg circle,
.iUcMblFAuq9LKn:disabled:active svg circle,
.iUcMblFAuq9LKn.ec7Rkt8YVvz6Rg:active svg circle,
.ctJF4taPJ4WGY0:disabled:active svg circle,
.ctJF4taPJ4WGY0.ec7Rkt8YVvz6Rg:active svg circle {
  stroke: var(--ds-text-disabled, #091e424f);
}
.u0Qu04nzhYsVX_ {
  padding: 6px 24px;
}
.PnEv2xIWy3eSui {
  width: 100%;
}
.NVlPKblNa7Tn3p {
  padding-top: 4px;
  padding-bottom: 4px;
}
.mUxzeT3eM0rCix {
  background: var(--ds-background-neutral-bold-hovered, rgba(0, 0, 0, 0.8));
  color: var(--ds-text-inverse, #ffffff);
}
.mUxzeT3eM0rCix:hover,
.mUxzeT3eM0rCix:focus,
.mUxzeT3eM0rCix:active {
  background: var(--ds-background-neutral-bold, rgba(0, 0, 0, 0.7));
  color: var(--ds-text-inverse, #ffffff);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nch-button": `bxgKMAm3lq5BpA`,
	"iconBefore": `gNDonYrNSA1GXJ`,
	"iconAfter": `Lm69QtwCvEGJyG`,
	"iconOnly": `fAvkXZrzkeHLoc`,
	"nch-button__iconButton": `HAVwIqCeMHpVKh`,
	"icon-sm": `Sg9WearZzOmo7n`,
	"nch-button__isReactButtonComponent": `SEj5vUdI3VvxDc`,
	"focusVisible": `y49lYPyzBoLRYa`,
	"nch-button--disabled": `ec7Rkt8YVvz6Rg`,
	"nch-button--primary": `SdamsUKjxSBwGb`,
	"nch-button--danger": `KpU415sFFvOzGZ`,
	"nch-button--link": `V_9lMAQOdk_AYt`,
	"nch-button--subtle": `iUcMblFAuq9LKn`,
	"nch-button--subtleLink": `ctJF4taPJ4WGY0`,
	"nch-button--wide": `u0Qu04nzhYsVX_`,
	"nch-button--fullwidth": `PnEv2xIWy3eSui`,
	"nch-button--loading": `NVlPKblNa7Tn3p`,
	"nch-button--selected": `mUxzeT3eM0rCix`
};
export default ___CSS_LOADER_EXPORT___;
