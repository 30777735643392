import { useMemberId } from '@trello/authentication';
import { atlassianTeams } from '@trello/config';
import { useFeatureFlag } from '@trello/feature-flag-client';

import { useShouldShowInternalToolsFragment } from './ShouldShowInternalToolsFragment.generated';

/**
 * Show internal tools if the user is allowed to view multiple channels,
 * or if we're not in a production environment (to make development easier).
 */
export const useShouldShowInternalTools = (
  hostname = window.location.hostname,
): boolean => {
  const memberId = useMemberId();
  const { data } = useShouldShowInternalToolsFragment({
    from: { id: memberId },
  });

  // eslint-disable-next-line @trello/no-feature-flag
  const isOnAtlassianTeam = useFeatureFlag('atlassian-team', false);

  if (isOnAtlassianTeam || hostname !== 'trello.com') {
    return true;
  }

  return !!(
    data?.email?.endsWith('@atlassian.com') &&
    data?.idOrganizations?.some((idOrg) => atlassianTeams.includes(idOrg))
  );
};
