import { isAdminOfAnyEnterprise } from '@trello/business-logic/member';

import { useIsAdminOfAnyEnterpriseQuery } from './IsAdminOfAnyEnterpriseQuery.generated';

export const useIsAdminOfAnyEnterprise = (
  idMember?: string | null,
): boolean => {
  const { data } = useIsAdminOfAnyEnterpriseQuery({
    variables: {
      id: idMember || '',
    },
    skip: !idMember,
    waitOn: ['MemberHeader'],
    fetchPolicy: 'cache-only',
  });
  if (!data?.member) {
    return false;
  }
  return isAdminOfAnyEnterprise(data?.member, data?.member?.organizations);
};
