import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { trelloFetch } from '@trello/fetch';
import { parseNetworkError } from '@trello/graphql-error-handling';
import { getNetworkClient } from '@trello/network-client';
import { getCsrfRequestPayload } from '@trello/session-cookie/csrf';

import type {
  MutationCreatePluginApplicationArgs,
  MutationUpdateApplicationArgs,
  QueryPluginApplicationArgs,
} from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import type { TrelloRestResolver } from '../types';

export const getPluginApplicationResolver: TrelloRestResolver<
  QueryPluginApplicationArgs
> = async (obj, args, context, info) => {
  const networkClient = getNetworkClient();

  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const member = args.forMember ? 'member' : '';
  const apiUrl = networkClient.getUrl(
    `/1/plugin/${args.id}/application/${member}`,
  );
  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'pluginApplication',
      operationName: context.operationName,
    },
  });

  const json = await response.json();

  if (response.ok) {
    return prepareDataForApolloCache(json, rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

export const createPluginApplication: TrelloRestResolver<
  MutationCreatePluginApplicationArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  if (!args.fields) {
    throw new Error('Expected fields argument is missing.');
  }

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/plugin/${args.id}/application`);
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...args.fields,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const application = await response.json();

  return prepareDataForApolloCache(application, rootNode);
};

export const updateApplication: TrelloRestResolver<
  MutationUpdateApplicationArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  if (!args.fields) {
    throw new Error('Expected fields argument is missing.');
  }

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/applications/${args.applicationKey}`);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...args.fields,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const application = await response.json();

  return prepareDataForApolloCache(application, rootNode);
};
