import { useCallback, useState } from 'react';

import { getNetworkError } from '@trello/graphql-error-handling';
import { localizeErrorCode } from '@trello/legacy-i18n';
import type { SecureString } from '@trello/privacy';

import { useExtendTrialPaidSubscriptionMutation } from './ExtendTrialPaidSubscriptionMutation.generated';
import { useStartWorkspacePaidSubscriptionMutation } from './StartWorkspacePaidSubscriptionMutation.generated';

export const useConfirmationForm = () => {
  const [acceptTOS, setAcceptTOS] = useState<boolean>(false);
  const [acceptTOSInvalid, setAcceptOTSInvalid] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [
    startSubscriptionMutation,
    { loading: isSubmittingStartSubscription },
  ] = useStartWorkspacePaidSubscriptionMutation();
  const [extendTrialMutation, { loading: isSubmittingExtendTrial }] =
    useExtendTrialPaidSubscriptionMutation();

  const onClickTermsOfService = useCallback(
    (checked: boolean) => {
      setAcceptTOS(checked);
      if (checked) {
        setAcceptOTSInvalid(false);
      }
    },
    [setAcceptTOS, setAcceptOTSInvalid],
  );

  const startSubscription = useCallback(
    async ({
      idOrganization,
      nonce,
      product,
      traceId,
      freeTrial,
    }: {
      idOrganization: string;
      nonce: SecureString;
      product: number;
      traceId?: string;
      freeTrial?: boolean;
    }) => {
      try {
        setSubmitError(null);

        return await startSubscriptionMutation({
          variables: {
            acceptTOS,
            idOrganization,
            nonce,
            product,
            traceId,
            freeTrial,
          },
        });
      } catch (error) {
        const networkError = getNetworkError(error);
        if (networkError?.code === 'BILLING_INVALID_TOS') {
          setAcceptOTSInvalid(true);
        } else {
          setSubmitError(
            localizeErrorCode(
              'paidAccount',
              networkError?.code ?? 'BILLING_SERVICE_UNAVAILABLE',
            ),
          );
        }
        throw error;
      }
    },
    [acceptTOS, setAcceptOTSInvalid, setSubmitError, startSubscriptionMutation],
  );

  const extendTrial = useCallback(
    async ({
      idOrganization,
      nonce,
      product,
      traceId,
    }: {
      idOrganization: string;
      nonce: SecureString;
      product: number;
      traceId?: string;
    }) => {
      try {
        setSubmitError(null);

        return await extendTrialMutation({
          variables: { acceptTOS, idOrganization, nonce, product, traceId },
        });
      } catch (error) {
        const networkError = getNetworkError(error);
        if (networkError?.code === 'BILLING_INVALID_TOS') {
          setAcceptOTSInvalid(true);
        } else {
          setSubmitError(
            localizeErrorCode(
              'paidAccount',
              networkError?.code ?? 'BILLING_SERVICE_UNAVAILABLE',
            ),
          );
        }
        throw error;
      }
    },
    [acceptTOS, setAcceptOTSInvalid, setSubmitError, extendTrialMutation],
  );

  return {
    acceptTOS,
    acceptTOSProps: {
      isChecked: acceptTOS,
      isDisabled: isSubmittingStartSubscription || isSubmittingExtendTrial,
      isInvalid: acceptTOSInvalid,
      onChange: onClickTermsOfService,
    },
    startSubscription,
    extendTrial,
    isSubmitting: isSubmittingStartSubscription || isSubmittingExtendTrial,
    submitError,
  };
};
