/* eslint-disable @trello/export-matches-filename */
import type { ComponentType } from 'react';

import type { GradientBackground } from '@trello/boards';
import { ColorBackgrounds, GradientBackgrounds } from '@trello/boards';
import { forTemplate } from '@trello/legacy-i18n';
import type { CreateBoardTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';
import type { Photo } from '@trello/unsplash';

import type { BackgroundItemState } from 'app/src/components/CreateBoardPopover/createMenuState';
import type { BackgroundItemProps } from './BackgroundItem';

const format = forTemplate('board_backgrounds');

/**
 *  Determines how many photos to show depending on whether or not we have photos loaded
 */
const getPhotosIndexRange = (
  photosLength: number,
  totalItemLength: number,
): number => {
  return Math.min(photosLength, totalItemLength);
};

/**
 *  Returns an array of photo BackgroundItem or BackgroundPopoverItem components
 */
export const getMappedBackgroundPhotoPickerComponents = (
  totalLength: number,
  Component: ComponentType<BackgroundItemProps>,
  photos: Photo[],
  selectedItem: BackgroundItemState,
  onSelect: (data: BackgroundItemState) => () => void,
) => {
  const photosIndexRange = getPhotosIndexRange(photos.length, totalLength);

  const photosMap = photos
    .slice(0, photosIndexRange)
    .map(({ id, urls, user }: Photo) => {
      if (!urls) {
        return null;
      }

      return (
        <Component
          testId={getTestId<CreateBoardTestIds>('background-picker-image')}
          key={`unsplash-${id}`}
          image={urls.small}
          user={user}
          selected={selectedItem.type === 'unsplash' && selectedItem.id === id}
          onSelect={onSelect({ type: 'unsplash', id })}
          isPhoto
        />
      );
    });

  return photosMap;
};

/**
 *  Returns an array of color BackgroundItem or BackgroundPopoverItem components
 */
export const getMappedBackgroundColorPickerComponents = (
  totalLength: number,
  Component: ComponentType<BackgroundItemProps>,
  selectedItem: BackgroundItemState,
  onSelect: (data: BackgroundItemState) => () => void,
) => {
  const gradients = Object.entries(GradientBackgrounds);
  const gradientsMap = gradients
    .slice(0, totalLength)
    .map(([id, metadata]: [string, GradientBackground]) => {
      return (
        <Component
          testId={getTestId<CreateBoardTestIds>('background-picker-color')}
          key={`gradient-${id}`}
          title={metadata.emoji}
          color={metadata.color}
          image={metadata.fullSizeUrl}
          selected={
            selectedItem.type === metadata.type && selectedItem.id === id
          }
          onSelect={onSelect({ type: 'gradient', id })}
        />
      );
    });

  return gradientsMap;
};

/**
 * Returns an array of color and photo BackgroundItem component. Either or both can be null
 * which means there is no shifted background. We need a shifted background if the
 * selection has an index number high enough that it would not ordinarily be shown in
 * the default group of background items. If there's no shifted background, returns early.
 */
export const getShiftedBackgroundItem = (
  totalLengthPhotos: number,
  totalLengthColors: number,
  Component: ComponentType<BackgroundItemProps>,
  photos: Photo[],
  selectedItem: BackgroundItemState,
  onSelect: (data: BackgroundItemState) => () => void,
  shiftedItem: BackgroundItemState,
) => {
  const { id, type } = shiftedItem;

  if (!id) {
    return [null, null];
  }

  const photosIndexRange = getPhotosIndexRange(
    photos.length,
    totalLengthPhotos,
  );
  const gradientIds = Object.keys(GradientBackgrounds);

  let colorComponent = null;
  let photoComponent = null;

  if (type === 'default') {
    const shiftedColor = ColorBackgrounds[id];
    const { color } = shiftedColor;

    colorComponent = (
      <Component
        testId={getTestId<CreateBoardTestIds>('background-picker-color')}
        key={`color-${id}`}
        title={format(id)}
        color={color}
        selected={selectedItem.id === id}
        onSelect={onSelect({ type, id })}
      />
    );
  } else if (type === 'gradient') {
    const shiftedGradient = GradientBackgrounds[id];
    const shiftedGradientIndex = gradientIds.indexOf(id);
    const { color, emoji, fullSizeUrl } = shiftedGradient;

    if (shiftedGradientIndex >= totalLengthColors) {
      colorComponent = (
        <Component
          testId={getTestId<CreateBoardTestIds>('background-picker-color')}
          key={`gradient-${id}`}
          title={emoji}
          color={color}
          image={fullSizeUrl}
          selected={selectedItem.id === id}
          onSelect={onSelect({ type, id })}
        />
      );
    }
  } else if (type === 'unsplash') {
    const shiftedPhotoIndex = photos.findIndex((photo) => id === photo.id);
    const shiftedPhoto = photos[shiftedPhotoIndex];
    const { urls, user } = shiftedPhoto || {};

    if (!urls) {
      return [null, null];
    }

    if (shiftedPhotoIndex >= photosIndexRange) {
      photoComponent = (
        <Component
          testId={getTestId<CreateBoardTestIds>('background-picker-image')}
          key={`unsplash-${id}`}
          image={urls.small}
          user={user}
          selected={selectedItem.id === id}
          onSelect={onSelect({ type, id })}
          isPhoto
        />
      );
    }
  }

  return [colorComponent, photoComponent];
};
