import type { FunctionComponent } from 'react';

import { isMemberLoggedIn } from '@trello/authentication';
import { token } from '@trello/theme';

import { LogoTrello } from 'app/src/components/LoggedOutHeader/LogoTrello';

// eslint-disable-next-line @trello/assets-alongside-implementation
import url from 'resources/images/header-logo-spirit-loading.gif';

export const LoggedOutHeaderSkeleton: FunctionComponent = () => (
  <div
    data-testid="logged-out-skeleton-header"
    style={{
      minHeight: 60,
      maxHeight: 60,
      background: token('elevation.surface', '#FFFFFF'),
      display: 'flex',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
      }}
    >
      <div style={{ margin: '0 14px' }}>
        <LogoTrello height={22.5} navSize="big" />
      </div>
      <div
        style={{
          alignItems: 'stretch',
          display: 'flex',
          flex: '1 0 0',
          padding: '20px 0 14px 0',
        }}
      >
        <div
          style={{
            backgroundColor: token('color.skeleton.subtle', '#091E4208'),
            height: 29,
            width: 104,
            borderRadius: 3,
            margin: '14px 2px',
          }}
        />
        <div
          style={{
            backgroundColor: token('color.skeleton.subtle', '#091E4208'),
            height: 29,
            width: 109,
            borderRadius: 3,
            margin: '14px 2px',
          }}
        />
        <div
          style={{
            backgroundColor: token('color.skeleton.subtle', '#091E4208'),
            height: 29,
            width: 78,
            borderRadius: 3,
            margin: '14px 2px',
          }}
        />
        <div
          style={{
            backgroundColor: token('color.skeleton.subtle', '#091E4208'),
            height: 29,
            width: 78,
            borderRadius: 3,
            margin: '14px 2px',
          }}
        />
        <div
          style={{
            backgroundColor: token('color.skeleton.subtle', '#091E4208'),
            height: 29,
            width: 115,
            borderRadius: 3,
            margin: '14px 2px',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            backgroundColor: token('color.skeleton.subtle', '#091E4208'),
            height: 32,
            width: 65,
            borderRadius: 3,
            margin: '0 16px',
          }}
        />
        <div
          style={{
            backgroundColor: token('color.background.brand.bold', '#0C66E4'),
            height: 60,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: token(
                'color.background.selected.pressed',
                'hsla(0, 0%, 100%, 0.3)',
              ),
              height: 32,
              width: 170,
              borderRadius: 3,
              margin: '0 16px',
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

interface HeaderSkeletonProps {
  backgroundColor?: string;
}

export const LoggedInHeaderSkeleton: FunctionComponent<HeaderSkeletonProps> = ({
  backgroundColor = token('color.background.neutral.bold', '#44546F'),
}) => (
  <div
    data-testid="logged-in-skeleton-header"
    style={{
      minHeight: 44,
      maxHeight: 44,
      backgroundColor,
    }}
  >
    <div
      style={{
        backgroundColor,
        height: 32,
        padding: '6px 4px',
        display: 'flex',
      }}
    >
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <div
          style={{
            backgroundColor: token(
              'color.background.accent.gray.subtle',
              'hsla(0, 0%, 100%, 0.3)',
            ),
            height: 32,
            width: 32,
            borderRadius: 3,
            marginRight: 4,
          }}
        />
        <div
          style={{
            backgroundImage: `url(${url})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            height: 32,
            width: 75,
            margin: '7px 8px 0px 2px',
            opacity: 0.5,
            position: 'relative',
            padding: '8px 0',
          }}
        />
        <div
          style={{
            alignItems: 'stretch',
            display: 'flex',
            flexGrow: 1,
            flexShrink: 0,
            flexBasis: 0,
            height: '100%',
            position: 'relative',
          }}
        >
          <div style={{ display: 'flex', margin: '0 4px' }}>
            <div
              style={{
                backgroundColor: token(
                  'color.background.accent.gray.subtle',
                  'hsla(0, 0%, 100%, 0.3)',
                ),
                height: 32,
                width: 124,
                borderRadius: 3,
                marginRight: 4,
              }}
            />
            <div
              style={{
                backgroundColor: token(
                  'color.background.accent.gray.subtle',
                  'hsla(0, 0%, 100%, 0.3)',
                ),
                height: 32,
                width: 90,
                borderRadius: 3,
                marginRight: 4,
              }}
            />
            <div
              style={{
                backgroundColor: token(
                  'color.background.accent.gray.subtle',
                  'hsla(0, 0%, 100%, 0.3)',
                ),
                height: 32,
                width: 90,
                borderRadius: 3,
                marginRight: 4,
              }}
            />
            <div
              style={{
                backgroundColor: token(
                  'color.background.accent.gray.subtle',
                  'hsla(0, 0%, 100%, 0.3)',
                ),
                height: 32,
                width: 110,
                borderRadius: 3,
                marginRight: 4,
              }}
            />
            <div
              style={{
                backgroundColor: token(
                  'color.background.accent.gray.subtle',
                  'hsla(0, 0%, 100%, 0.3)',
                ),
                height: 32,
                width: 68,
                borderRadius: 3,
                margin: '0px 4px 0 12px',
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
        <div
          style={{
            backgroundColor: token(
              'color.background.accent.gray.subtle',
              'hsla(0, 0%, 100%, 0.3)',
            ),
            height: 32,
            width: 250,
            borderRadius: 3,
            marginRight: 4,
          }}
        />
        <div
          style={{
            backgroundColor: token(
              'color.background.accent.gray.subtle',
              'hsla(0, 0%, 100%, 0.3)',
            ),
            height: 32,
            width: 32,
            borderRadius: 3,
            marginRight: 4,
          }}
        />
        <div
          style={{
            backgroundColor: token(
              'color.background.accent.gray.subtle',
              'hsla(0, 0%, 100%, 0.3)',
            ),
            height: 32,
            width: 32,
            borderRadius: 3,
            marginRight: 4,
          }}
        />
        <div
          style={{
            backgroundColor: token(
              'color.background.accent.gray.subtle',
              'hsla(0, 0%, 100%, 0.3)',
            ),
            height: 32,
            width: 32,
            borderRadius: 3,
            marginRight: 4,
          }}
        />
        <div
          style={{
            backgroundColor: token(
              'color.background.accent.gray.subtle',
              'hsla(0, 0%, 100%, 0.3)',
            ),
            height: 32,
            width: 32,
            borderRadius: 3,
            marginRight: 4,
          }}
        />
        <div
          style={{
            backgroundColor: token(
              'color.background.accent.gray.subtle',
              'hsla(0, 0%, 100%, 0.3)',
            ),
            height: 32,
            width: 32,
            borderRadius: '50%',
          }}
        />
      </div>
    </div>
  </div>
);

export const HeaderSkeleton: FunctionComponent<HeaderSkeletonProps> = (props) =>
  isMemberLoggedIn() ? (
    <LoggedInHeaderSkeleton {...props} />
  ) : (
    <LoggedOutHeaderSkeleton />
  );
