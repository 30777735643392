/* eslint-disable formatjs/enforce-description */
import type { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { asPercentage } from '@trello/legacy-i18n';

import styles from './SavePercentageTag.less';

interface OwnProps {
  percentage: number;
}

export const SavePercentageTag: FunctionComponent<OwnProps> = ({
  percentage,
}) => {
  return (
    <div className={styles.tag}>
      <FormattedMessage
        id="templates.billing_page_one.discount-percent"
        defaultMessage="SAVE {discountPercent}"
        values={{
          discountPercent: asPercentage(percentage / 100),
        }}
      />
    </div>
  );
};
