import { roundRect } from './roundRect';

const SIZE = 64;
const RADIUS = 15;

interface DrawFaviconOptions {
  color?: string;
  bottomColor?: string;
  topColor?: string;
  hasNotifications?: boolean;
  tiled?: boolean;
  useDefault?: boolean;
}

export const drawFavicon = (
  img: HTMLImageElement | undefined,
  options: DrawFaviconOptions,
) => {
  const canvas = document.createElement('canvas');
  canvas.height = SIZE;
  canvas.width = SIZE;

  const ctx =
    typeof canvas.getContext === 'function' ? canvas.getContext('2d') : null;

  if (ctx === null) {
    throw Error("Can't render to the canvas!");
  }

  ctx.save();

  if (img) {
    roundRect(ctx, 0, 0, SIZE, SIZE, 8, false, false, null);
    ctx.clip();

    if (options.tiled) {
      ctx.drawImage(img, 0, 0);
    } else {
      const { width, height } = img;
      if (width > height) {
        ctx.drawImage(img, 0, 0, (SIZE * width) / height, SIZE);
      } else {
        ctx.drawImage(img, 0, 0, SIZE, (SIZE * height) / width);
      }
    }
  } else if (options.topColor && options.bottomColor) {
    const gradientFill = ctx.createLinearGradient(0, 0, 51, 74);
    gradientFill.addColorStop(0, options.topColor);
    gradientFill.addColorStop(1, options.bottomColor);
    roundRect(ctx, 0, 0, SIZE, SIZE, 12, true, true, gradientFill);
  } else {
    if (options.useDefault) {
      const defaultFill = ctx.createLinearGradient(0, 0, 0, 32);
      defaultFill.addColorStop(0, '#298FCA');
      defaultFill.addColorStop(1, '#0079BF');
      roundRect(ctx, 0, 0, SIZE, SIZE, 12, true, true, defaultFill);
    } else {
      const colorFill = options.color ? options.color : '#0079BF';
      roundRect(ctx, 0, 0, SIZE, SIZE, 12, true, true, colorFill);
    }
  }

  roundRect(ctx, 9, 9, 19, 44, 6, true, true, '#FFFFFF');
  roundRect(ctx, 36, 9, 19, 29, 6, true, true, '#FFFFFF');

  ctx.restore();

  if (options.hasNotifications) {
    ctx.beginPath();
    ctx.arc(SIZE - RADIUS, RADIUS, RADIUS, 0, 2 * Math.PI, false);
    ctx.fillStyle = '#EB5A46';
    ctx.fill();
    ctx.lineWidth = 4;
    ctx.strokeStyle = '#FFFFFF';
    ctx.stroke();
    ctx.closePath();
  }

  return canvas.toDataURL('image/png');
};
