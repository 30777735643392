// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("resources/images/spinner.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H9CXYMuyLaox4Z.k6OXeEbRotbIa2.wDucPXcc2Li1NT {
  text-align: center;
}
.H9CXYMuyLaox4Z.k6OXeEbRotbIa2 > .Fccy0HptNUV4kR {
  margin: 0 auto;
}
.H9CXYMuyLaox4Z.wDucPXcc2Li1NT > .Fccy0HptNUV4kR {
  display: inline-block;
  vertical-align: middle;
}
.Fccy0HptNUV4kR {
  display: block;
  width: 30px;
  height: 30px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 30px 30px;
}
.Fccy0HptNUV4kR.OZfanbMd9LU1Mp {
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
}
.Fccy0HptNUV4kR.HLW4bk6Dg2Sdxd {
  margin-right: 3px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `H9CXYMuyLaox4Z`,
	"centered": `k6OXeEbRotbIa2`,
	"inline": `wDucPXcc2Li1NT`,
	"spinner": `Fccy0HptNUV4kR`,
	"small": `OZfanbMd9LU1Mp`,
	"modLeft": `HLW4bk6Dg2Sdxd`
};
export default ___CSS_LOADER_EXPORT___;
