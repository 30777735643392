// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T8SuBG8lWso1my {
  color: var(--ds-text, #172b4d);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-display: swap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  cursor: pointer;
}
.T8SuBG8lWso1my li {
  list-style-type: none;
}
.uyZlYSJVAt4xf9 {
  padding: 6px 12px;
  list-style-type: none;
}
.uyZlYSJVAt4xf9:hover {
  background-color: var(--ds-background-neutral-hovered, #091e4224);
}
.uyZlYSJVAt4xf9:active {
  background-color: var(--ds-background-neutral-pressed, #091e424f);
}
.cvne1mWE33iNMl {
  color: var(--ds-text, #172b4d);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-display: swap;
  display: grid;
  grid-auto-columns: 44px;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: 48px;
  cursor: pointer;
}
.cvne1mWE33iNMl:hover {
  background-color: var(--ds-background-neutral-hovered, #091e4224);
}
.cvne1mWE33iNMl:active {
  background-color: var(--ds-background-neutral-pressed, #091e424f);
}
.vAPGYWWzujLwaL {
  grid-column-end: 1;
  margin: auto 0 auto auto;
}
.tONJvKrAZwZgEq {
  padding: 6px 12px;
}
.n73NBWPTGnPYdB {
  color: var(--ds-text-selected, #0c66e4);
}
.YB2AMZtv_hcfMl {
  color: var(--ds-text-subtle, #44546f);
  font-size: 12px;
  line-height: 16px;
}
.sXydljbJrL01bE {
  color: var(--ds-text-selected, #0c66e4);
}
.YQyVEkqhZsG_le {
  color: var(--ds-text, #172b4d);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-display: swap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  cursor: pointer;
}
.YQyVEkqhZsG_le li {
  list-style-type: none;
}
.YQyVEkqhZsG_le li {
  padding: 6px 12px;
  list-style-type: none;
}
.YQyVEkqhZsG_le li:hover {
  background-color: var(--ds-background-neutral-hovered, #091e4224);
}
.YQyVEkqhZsG_le li:active {
  background-color: var(--ds-background-neutral-pressed, #091e424f);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nch-list": `T8SuBG8lWso1my`,
	"nch-list__item": `uyZlYSJVAt4xf9`,
	"nch-list__cell": `cvne1mWE33iNMl`,
	"nch-list__cell-image": `vAPGYWWzujLwaL`,
	"nch-list__cell-content": `tONJvKrAZwZgEq`,
	"nch-list__cell--selected": `n73NBWPTGnPYdB`,
	"nch-list__cell-meta": `YB2AMZtv_hcfMl`,
	"nch-list__cell-meta--selected": `sXydljbJrL01bE`,
	"nch-list--static": `YQyVEkqhZsG_le`
};
export default ___CSS_LOADER_EXPORT___;
