// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lTkaSx9GA2Gy2B {
  margin-top: 4px;
  color: var(--ds-text-danger, #ae2a19);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"validationError": `lTkaSx9GA2Gy2B`
};
export default ___CSS_LOADER_EXPORT___;
