// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gRz5T7UPIdkhxr {
  position: relative;
  z-index: 0;
  height: 100%;
}
.f9u2aN785DWIzt,
.GhEGpdnkfIIF9j,
.dyDgERGuqU3iMl {
  position: absolute;
  top: 0;
  left: 0;
}
@media print {
  .f9u2aN785DWIzt,
  .GhEGpdnkfIIF9j,
  .dyDgERGuqU3iMl {
    position: relative;
  }
}
.dyDgERGuqU3iMl {
  z-index: 20;
}
.GhEGpdnkfIIF9j {
  z-index: 19;
}
.f9u2aN785DWIzt {
  z-index: 80;
}
.gNy2mgwvOBJRgA {
  position: fixed;
  z-index: 2;
  top: 40px;
  right: 4px;
  max-width: 270px;
}
.sRyiAbwZ6irW2E {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
}
.j6gdubt3tCF3U1 {
  position: absolute;
  z-index: 70;
  top: 0;
  left: 0;
}
.s4c1uussUFNZ29 {
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  left: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appLayer": `gRz5T7UPIdkhxr`,
	"overlayLayer": `f9u2aN785DWIzt`,
	"discoveryAdLayer": `GhEGpdnkfIIF9j`,
	"cardBackLayer": `dyDgERGuqU3iMl`,
	"alertLayer": `gNy2mgwvOBJRgA`,
	"flagLayer": `sRyiAbwZ6irW2E`,
	"popoverLayer": `j6gdubt3tCF3U1`,
	"tooltipLayerRef": `s4c1uussUFNZ29`
};
export default ___CSS_LOADER_EXPORT___;
