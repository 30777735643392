// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o7EAj6bxSlZptk {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 0 4px 0 0;
  padding: 0;
  border: 0;
  border-radius: 3px;
  box-shadow: none;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none;
  white-space: nowrap;
}
.o7EAj6bxSlZptk.k4qhoaAfTwigA0 {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .o7EAj6bxSlZptk.k4qhoaAfTwigA0 {
  outline-color: -webkit-focus-ring-color;
}
.o7EAj6bxSlZptk.k4qhoaAfTwigA0 .mac.firefox {
  outline-color: AccentColor;
}
.o7EAj6bxSlZptk.czuKT__IUwReaX {
  background-color: var(--ds-background-danger-bold, #ca3521);
}
.o7EAj6bxSlZptk .Iq8Sa6dL5R5bmn {
  padding: 0 12px;
}
.o7EAj6bxSlZptk .ckKgC6Moz3kMqy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerButton": `o7EAj6bxSlZptk`,
	"focusRing": `k4qhoaAfTwigA0`,
	"alert": `czuKT__IUwReaX`,
	"buttonText": `Iq8Sa6dL5R5bmn`,
	"headerButtonIcon": `ckKgC6Moz3kMqy`
};
export default ___CSS_LOADER_EXPORT___;
