import type { FunctionComponent } from 'react';
import { useCallback, useRef } from 'react';

import { forTemplate } from '@trello/legacy-i18n';
import { wait } from '@trello/time';

const format = forTemplate('file_link_handler');

interface FileLinkComponentProps {
  url: string;
}

export const FileLinkComponent: FunctionComponent<FileLinkComponentProps> = ({
  url,
}) => {
  const inputEl = useRef<HTMLInputElement>(null);

  // Automatically select the text in the input when the user clicks into it
  // but we need to defer the select call due to default browser behavior.
  const onClick = useCallback(async () => {
    await wait(0);
    inputEl.current?.select();
  }, []);

  return (
    <div>
      <p>
        {format('browsers-prevent-file-links')}
        <input
          type="text"
          ref={inputEl}
          value={url}
          readOnly={true}
          className="js-autofocus"
          onClick={onClick}
        />
      </p>
    </div>
  );
};
