// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qEmwqlOtkpdMPN {
  display: block;
  max-width: 600px;
  margin: 35px auto;
  color: var(--ds-text-subtle, #44546f);
  text-align: center;
}
.qEmwqlOtkpdMPN h1 {
  margin-top: 20px;
  margin-bottom: 24px;
  font-size: 26px;
}
.qEmwqlOtkpdMPN p {
  font-size: 18px;
  line-height: 22px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMessage": `qEmwqlOtkpdMPN`
};
export default ___CSS_LOADER_EXPORT___;
