import { makeErrorEnum } from './makeErrorEnum';

/* eslint-disable-next-line @trello/no-module-logic */
export const ApiError = makeErrorEnum('API', [
  'Unauthorized',
  'Unauthenticated',
  'Unconfirmed',
  'Timeout',
  'NoResponse',
  'NotFound',
  'PreconditionFailed',
  'Server',
  'RequestBlocked',
  'BadRequest',
  'Conflict',
  'TooManyRequests',
  'Other',
]);
