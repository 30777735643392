/**
 * Polyfill for https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded.
 */
export function scrollIntoViewIfNeeded(element: Element | null): void {
  if (!element) {
    return;
  }

  const rect = element.getBoundingClientRect();

  if (rect.bottom > window.innerHeight) {
    element.scrollIntoView(false);
  } else if (rect.top < 0) {
    element.scrollIntoView();
  }
}
