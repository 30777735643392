// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XTquuX_nGL14Ly {
  position: relative;
}
.CZyKNkLM4pHTzR {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}
.FmJtGwK7HAfpbq {
  margin-bottom: 21px;
  font-size: 14px;
  line-height: 20px;
}
.c_ZJrJJ0nYXdPc {
  position: absolute;
  right: 0;
  bottom: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PremiumTrialPaymenConfirmation": `XTquuX_nGL14Ly`,
	"title": `CZyKNkLM4pHTzR`,
	"description": `FmJtGwK7HAfpbq`,
	"cancelButton": `c_ZJrJJ0nYXdPc`
};
export default ___CSS_LOADER_EXPORT___;
