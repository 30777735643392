import { navigate } from '@trello/router/navigate';
import { TrelloStorage } from '@trello/storage';

import { getHomeUrl } from 'app/scripts/controller/urls';
import { Auth } from 'app/scripts/db/Auth';
import { ModelCache } from 'app/scripts/db/ModelCache';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMemberOfOrg = function (orgname: any) {
  const me = ModelCache.get('Member', Auth.myId());
  const orgs = me?.getSortedOrgs();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return orgs?.find((org: any) => org.get('name') === orgname) !== undefined;
};

export const getHomeLastTabStorageKey = () => `home_${Auth.myId()}_last_tab_2`;

export const getHomeDefaultTab = function () {
  return getHomeUrl(Auth.myUsername(), null, true);
};

export const getHomeLastTab = function () {
  TrelloStorage.unset(`home_${Auth.myId()}_last_tab`); // Clean up old key

  // @ts-expect-error
  const defaultTab = getHomeDefaultTab.call(this);
  const lastHomeTab = TrelloStorage.get(getHomeLastTabStorageKey());

  // eslint-disable-next-line eqeqeq
  if (lastHomeTab == null) {
    return defaultTab;
  }

  if (
    !(
      lastHomeTab === '/' ||
      lastHomeTab.endsWith('/boards') ||
      lastHomeTab.endsWith('/home') ||
      lastHomeTab.endsWith('/getting-started')
    )
  ) {
    // Clear out any invalid values
    TrelloStorage.unset(getHomeLastTabStorageKey());
    return defaultTab;
  }

  return lastHomeTab;
};

export const redirectHomeToDefaultTab = function () {
  TrelloStorage.unset(getHomeLastTabStorageKey());
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  return navigate(getHomeDefaultTab.call(this), {
    trigger: true,
    replace: true,
  });
};
