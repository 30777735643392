import type { RefObject } from 'react';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

export function useHasBeenInViewport(ref: RefObject<HTMLElement | null>) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => {
    return new IntersectionObserver((entries) => {
      // set isIntersecting based on the most _recent_ IntersectionObserverEntry
      const latestEntry = entries[entries.length - 1];
      setIsIntersecting(latestEntry.isIntersecting);
    });
  }, []);

  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  useLayoutEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [observer, ref]);

  useEffect(() => {
    if (isIntersecting) {
      setIsIntersecting(true);
      observer.disconnect();
    }
  }, [isIntersecting, observer]);

  return isIntersecting;
}
