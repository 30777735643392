// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Jnz4aUHuwxuZUg {
  font-size: 14px;
  text-transform: uppercase;
}
.wP4P52QcW9qhGA {
  margin: 32px 0 16px 0;
}
.LRl_qfPadzRW54 {
  margin-bottom: 16px;
}
.z2cy6jvQhNoxy2:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--ds-border-focused, #388bff);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step": `Jnz4aUHuwxuZUg`,
	"summarySection": `wP4P52QcW9qhGA`,
	"submitError": `LRl_qfPadzRW54`,
	"submit": `z2cy6jvQhNoxy2`
};
export default ___CSS_LOADER_EXPORT___;
