import { useEffect, useState } from 'react';

import type { SupportedFlagTypes } from '@atlassiansox/feature-flag-web-client';

import type { featureFlags } from './data/featureFlags';
import { featureFlagClient } from './index';

export const useFeatureFlag: {
  <T extends boolean>(
    flagName: keyof typeof featureFlags,
    defaultValue: T,
    options?: {
      sendExposureEvent?: boolean;
      attributes?: object;
    },
  ): boolean;
  <T extends SupportedFlagTypes>(
    flagName: keyof typeof featureFlags,
    defaultValue: T,
    options?: {
      sendExposureEvent?: boolean;
      attributes?: object;
    },
  ): T;
} = <T extends SupportedFlagTypes>(
  flagName: keyof typeof featureFlags,
  defaultValue: T,
  options?: {
    sendExposureEvent?: boolean;
    attributes?: object;
  },
): T => {
  // Get the initial value from LaunchDarkly (it may fall back to the defaultValue)
  // if the LaunchDarkly client isn't ready yet
  // By providing the option "sendExposureEvent" it will call getTrackedVariation
  // and triggerExposureEvent for analytics
  const [flag, setFlag] = useState(() =>
    options?.sendExposureEvent
      ? featureFlagClient.getTrackedVariation(
          flagName,
          defaultValue,
          options.attributes,
        )
      : featureFlagClient.get(flagName, defaultValue),
  );

  // Subscribe to updates from LaunchDarkly, and update our flag state with the
  // new value
  useEffect(() => {
    const onFlagChanged = (newValue: T) => setFlag(newValue);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    featureFlagClient.on(flagName, defaultValue, onFlagChanged);

    // Ensure the subscription is cleaned up when the component is unmounted, or
    // the flagName changes for some reason
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return () => featureFlagClient.off(flagName, onFlagChanged);
  }, [defaultValue, flagName]);

  return flag;
};
