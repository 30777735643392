import { isMemberLoggedIn, useMemberId } from '@trello/authentication';

import type { MyAdminRolesQuery } from './MyAdminRolesQuery.generated';
import { useMyAdminRolesQuery } from './MyAdminRolesQuery.generated';

/**
 * Returns the member with properties related to the member's PremOrgs or Enterprise Admin
 * roles if applicable.
 */
export const useMyAdminRoles = (): MyAdminRolesQuery => {
  const memberId = useMemberId();
  const { data } = useMyAdminRolesQuery({
    variables: {
      id: memberId,
    },
    skip: !isMemberLoggedIn(),
    waitOn: ['MemberHeader'],

    // Obtaining information about a member's Enterprise Admin role can be a
    // costly operation. The work to obtain this information is done up front
    // during quickload with the `MemberHeader` query (hence the corresponding
    // `waitOn`). We set the fetch policy for this `useMyAdminRolesQuery` query
    // to `cache-only` to ensure we use the cached data, avoiding unnessary
    // calls in the future. Consider modifying the quickload query/queries
    // before making changes to the `useMyAdminRolesQuery` query.
    fetchPolicy: 'cache-only',
  });

  if (!data?.member) {
    return {} as MyAdminRolesQuery;
  }

  return data;
};
