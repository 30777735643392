import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
import process from 'process';
export type ShouldShowInternalToolsFragment = (
  { __typename: 'Member' }
  & Pick<Types.Member, 'email' | 'idOrganizations'>
);

export const ShouldShowInternalToolsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShouldShowInternalTools' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Member' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idOrganizations' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseShouldShowInternalToolsFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      ShouldShowInternalToolsFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseShouldShowInternalToolsFragmentResult
  extends Omit<
    Apollo.UseFragmentResult<ShouldShowInternalToolsFragment>,
    'data'
  > {
  data?: ShouldShowInternalToolsFragment;
}

export const useShouldShowInternalToolsFragment = ({
  from,
  returnPartialData,
  ...options
}: UseShouldShowInternalToolsFragmentOptions): UseShouldShowInternalToolsFragmentResult => {
  const result = Apollo.useFragment<ShouldShowInternalToolsFragment>({
    ...options,
    fragment: ShouldShowInternalToolsFragmentDoc,
    fragmentName: 'ShouldShowInternalTools',
    from: {
      __typename: 'Member',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Fragment data is incomplete.', result);
    }
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as ShouldShowInternalToolsFragment };
};
