// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r9Un6wCUUwxgzw {
  margin-left: 4px;
}
.rnc85p8EBQu5i6 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--dynamic-button);
  color: var(--dynamic-text);
}
.rnc85p8EBQu5i6.HDo1EezaE2jGbs {
  border-radius: 3px;
}
.rnc85p8EBQu5i6 svg circle {
  stroke: var(--dynamic-icon);
}
.rnc85p8EBQu5i6:hover {
  background-color: var(--dynamic-button-hovered);
  color: var(--dynamic-text);
}
.rnc85p8EBQu5i6:hover svg circle {
  stroke: var(--dynamic-icon);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchButton": `r9Un6wCUUwxgzw`,
	"searchIcon": `rnc85p8EBQu5i6`,
	"griffin": `HDo1EezaE2jGbs`
};
export default ___CSS_LOADER_EXPORT___;
