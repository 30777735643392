import type {
  FeatureExperimentKeys,
  FeatureExperimentParameters,
  FeatureLayerParameters,
  FeatureLayersKeys,
} from './data/featureGates';
import { useGetExperimentValue } from './useGetExperimentValue';

interface UseGetLayerValueArgs<K, P> {
  layerName: K;
  parameter: P;
  fireExposureEvent?: boolean;
  options?: {
    isLayer: boolean;
  };
}

export const useGetLayerValue = <
  K extends FeatureLayersKeys,
  P extends FeatureLayerParameters<K>,
>({
  layerName,
  parameter,
  fireExposureEvent,
}: UseGetLayerValueArgs<K, P>) => {
  return useGetExperimentValue({
    experimentName: layerName as unknown as FeatureExperimentKeys,
    parameter: parameter as FeatureExperimentParameters<FeatureExperimentKeys>,
    fireExposureEvent,
    options: {
      isLayer: true,
    },
  });
};
