// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// @ts-expect-error TS(2339): Property 'testThatErrorReportingStillWorks' does n... Remove this comment to see the full error message
window.testThatErrorReportingStillWorks = () =>
  setTimeout(function () {
    throw new Error('Error reporting still works!');
  }, 1000);
