/* eslint-disable @trello/disallow-filenames */

// eslint-disable-next-line no-restricted-imports
import type { PremiumFeatures as PremiumFeaturesFromGraphQLSchema } from '@trello/graphql/generated';

export const PremiumFeatures: Record<
  PremiumFeaturesFromGraphQLSchema,
  PremiumFeaturesFromGraphQLSchema
> = {
  activity: 'activity',
  additionalBoardBackgrounds: 'additionalBoardBackgrounds',
  additionalStickers: 'additionalStickers',
  advancedChecklists: 'advancedChecklists',
  atlassianIntelligence: 'atlassianIntelligence',
  boardExport: 'boardExport',
  butler: 'butler',
  butlerBC: 'butlerBC',
  butlerEnterprise: 'butlerEnterprise',
  butlerPremium: 'butlerPremium',
  butlerStandard: 'butlerStandard',
  collapsibleLists: 'collapsibleLists',
  crown: 'crown',
  csvExport: 'csvExport',
  customBoardBackgrounds: 'customBoardBackgrounds',
  customEmoji: 'customEmoji',
  customStickers: 'customStickers',
  deactivated: 'deactivated',
  enterpriseUI: 'enterpriseUI',
  export: 'export',
  externallyBilled: 'externallyBilled',
  goldMembers: 'goldMembers',
  googleApps: 'googleApps',
  infinitePlugins: 'infinitePlugins',
  inviteBoard: 'inviteBoard',
  inviteOrg: 'inviteOrg',
  isBC: 'isBC',
  isPremium: 'isPremium',
  isStandard: 'isStandard',
  labelVariants: 'labelVariants',
  largeAttachments: 'largeAttachments',
  listColors: 'listColors',
  multiBoardGuests: 'multiBoardGuests',
  observers: 'observers',
  paidCorePlugins: 'paidCorePlugins',
  plugins: 'plugins',
  premiumMirrorCards: 'premiumMirrorCards',
  privateTemplates: 'privateTemplates',
  readSecrets: 'readSecrets',
  removal: 'removal',
  restrictVis: 'restrictVis',
  savedSearches: 'savedSearches',
  shortExportHistory: 'shortExportHistory',
  starCounts: 'starCounts',
  stats: 'stats',
  superAdmins: 'superAdmins',
  tags: 'tags',
  threePlugins: 'threePlugins',
  views: 'views',
  workspaceViews: 'workspaceViews',
  trelloSso: 'trelloSso',
  selfServeExpansion: 'selfServeExpansion',
  ccpBilling: 'ccpBilling',
};

export type PremiumFeature = PremiumFeaturesFromGraphQLSchema;
