// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
const t = teacupWithHelpers('dialog_close_button');

export const DialogCloseButtonTemplate = () =>
  t.a('.icon-md.icon-close.dialog-close-button.js-close-window', {
    href: '#',
    'aria-label': t.l('close-dialog-label'),
    role: 'button',
  });
