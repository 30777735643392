import { useEffect } from 'react';

import { isMemberLoggedIn } from '@trello/authentication';
import { realtimeUpdaterEvents } from '@trello/realtime-updater';

import { Auth } from 'app/scripts/db/Auth';

export const useInvalidModelHandler = () => {
  useEffect(() => {
    // If we're unable to subscribe to our own member channel, something
    // has gone wrong (possibly we deleted our account or our session was
    // invalidated from another browser)
    if (isMemberLoggedIn()) {
      realtimeUpdaterEvents.on(
        'invalidModel',
        (typeName: string, id: string) => {
          if (typeName === 'Member' && Auth.isMe(id)) {
            Auth.removeToken();
          }
        },
      );
    }
  }, []);
};
