// Ensure that keys are sorted, to reduce merge conflicts
/* eslint sort-keys: "error" */

import type { SupportedFlagTypes } from '@atlassiansox/feature-flag-web-client';

interface FeatureFlagManifestValue {
  createdOn: string;
  defaultValue: SupportedFlagTypes;
  owner: string;
}
const asFeatureFlagsEnum = <T>(ff: {
  [K in keyof T]: FeatureFlagManifestValue;
}) => ff;

/**
 * Feature flags in LaunchDarkly have to be defined here as well.
 * The `owner` field should be your Trello username.
 */
// eslint-disable-next-line @trello/no-module-logic
export const featureFlags = asFeatureFlagsEnum({
  'atlassian-team': {
    createdOn: '2022-11-23',
    defaultValue: false,
    owner: 'rwang3',
  },
  'fep.apollo-read-write-metrics': {
    createdOn: '2022-06-22',
    defaultValue: false,
    owner: 'dcilley',
  },
  'fep.apollo_cache_hydrator.metrics': {
    createdOn: '2019-10-11',
    defaultValue: false,
    owner: 'vkozachok',
  },
  'fep.disconnect_active_clients': {
    createdOn: '2020-06-19',
    defaultValue: false,
    owner: 'mfaith',
  },
  'fep.error-handling-rrs': {
    createdOn: '2022-04-04',
    defaultValue: false,
    owner: 'dcilley',
  },
  'fep.log-connection-information': {
    createdOn: '2022-08-15',
    defaultValue: false,
    owner: 'vkozachok',
  },
  'fep.max-first-active-delay': {
    createdOn: '2024-10-28',
    defaultValue: 1800,
    owner: 'vkozachok',
  },
  'fep.max-first-idle-delay': {
    createdOn: '2024-10-23',
    defaultValue: 27000,
    owner: 'vkozachok',
  },
  'fep.native-current-board-full': {
    createdOn: '2023-10-02',
    defaultValue: 'true',
    owner: 'jcostello2',
  },
  'fep.operation-name-in-url': {
    createdOn: '2021-09-15',
    defaultValue: false,
    owner: 'vkozachok',
  },
  'fep.react-routing': {
    createdOn: '2023-04-03',
    defaultValue: false,
    owner: 'vkozachok',
  },
  'fep.take_trello_offline': {
    createdOn: '2020-09-25',
    defaultValue: false,
    owner: 'mfaith',
  },
  'fep.use-trello-timing-header': {
    createdOn: '2021-11-01',
    defaultValue: false,
    owner: 'dcilley',
  },
  'fep.user-session-tracking': {
    createdOn: '2023-06-20',
    defaultValue: false,
    owner: 'dcilley',
  },
  'platform.overlay_deprecation_whitelist': {
    createdOn: '2019-01-03',
    defaultValue: false,
    owner: 'mcowan',
  },
  trello_jwm_crossflow_non_ppm_free_single_player: {
    createdOn: '2024-01-10',
    defaultValue: 'not-enrolled',
    owner: 'achung',
  },
  'web.forcefully-reload-to-exact-version': {
    createdOn: '2022-01-18',
    defaultValue: false,
    owner: 'mfaith',
  },
  'web.passively-reload-to-minimum-version': {
    createdOn: '2022-05-11',
    defaultValue: false,
    owner: 'kmarshall',
  },
  'workflowers.butler-ent-admin-only-allowlist': {
    createdOn: '2020-10-21',
    defaultValue: false,
    owner: 'afecenko',
  },
  'xf.statsig-hydrate-shared-state': {
    createdOn: '2024-05-06',
    defaultValue: false,
    owner: 'achung',
  },
});

export type RegisteredFeatureFlagKey = keyof typeof featureFlags;
