import type { FunctionComponent, PropsWithChildren } from 'react';

import { POPOVER_BOUNDARY_ELEMENT_ID } from './Popover';

import styles from './PopoverBoundary.less';

export const PopoverBoundary: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => (
  <main id={POPOVER_BOUNDARY_ELEMENT_ID} className={styles.popoverBoundary}>
    {children}
  </main>
);
