import { ApolloProvider } from '@trello/graphql';
import { TrelloIntlProvider } from '@trello/i18n';

import { TrelloOnline } from 'app/src/components/TrelloOnline';
import { useApolloReadWriteReporter } from './useApolloReadWriteReporter';
import { useOfflineState } from './useOfflineState';
import { useQuickload } from './useQuickload';
import { useSecurityPolicyViolationReporter } from './useSecurityPolicyViolationReporter';

export const App = () => {
  const wasEverOffline = useOfflineState();
  useQuickload();
  useApolloReadWriteReporter();
  useSecurityPolicyViolationReporter();

  if (wasEverOffline) {
    window.location.href = `/offline?returnUrl=${encodeURIComponent(
      window.location.pathname,
    )}`;
  }

  return (
    <TrelloIntlProvider>
      <ApolloProvider>
        <TrelloOnline />
      </ApolloProvider>
    </TrelloIntlProvider>
  );
};
