const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value ?? 0, min), max);
};

interface DeferOpts {
  pollingInterval?: number;
  iterations?: number;
}

const defaultOptions: DeferOpts = {
  pollingInterval: 5,
  iterations: 40,
};

/**
 * @description This function is used to defer the execution of a callback until a condition is met. Using the default
 * settings, the function will poll every 5ms for a maximum of 40 iterations. But is configurable to poll every 5ms to
 * 20ms for a maximum of 1 to 25 iterations.
 *
 * @deprecated Do not use this function in new code! This function is ONLY in use for migrating legacy backbone code
 * into React.
 *
 * @param condition A function that returns a boolean. If the function returns true, the callback will be executed.
 * @param callback A function that will be executed when the condition is met.
 * @param options An object that can be used to configure the polling interval (between 5ms and 20ms) and the maximum
 * number of iterations to wait (between 1 and 25).
 */
export const deferUntil = async (
  condition: () => boolean,
  callback: () => void,
  options?: DeferOpts,
) => {
  const delayTime = clamp(
    options?.pollingInterval! ?? defaultOptions.pollingInterval,
    5,
    20,
  );

  const maxIterations = clamp(
    options?.iterations! ?? defaultOptions.iterations,
    1,
    25,
  );

  let i = 0;

  while (!condition() && i <= maxIterations) {
    if (i >= maxIterations) {
      const e = new Error('deferUntil timed out');
      console.error(e);
      break;
    }
    await delay(delayTime);
    i++;
  }
  callback();
};
