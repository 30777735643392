import BluebirdPromise from 'bluebird';

import { navigationState } from '@trello/router';

export const getSpinner = () => {
  navigationState.setValue({
    isNavigating: true,
  });

  return BluebirdPromise.resolve().disposer(function () {
    navigationState.setValue({
      isNavigating: false,
    });
  });
};
