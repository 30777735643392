// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A3PtEe1rGIm_yL {
  display: inline-block;
  line-height: 1;
}
.Xz0CP82_7pTHvC svg {
  width: 14px;
  height: 14px;
  line-height: 14px;
}
.neoUEAwI0GETBQ svg {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.J2CpPoHYfZ2U6i svg {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.BKkTHQrUsCkzXR svg {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.UaKa9zr_Vk1d5U {
  box-sizing: border-box;
  padding: 3px 3px;
}
.O5y03M4XzMgBNa {
  box-sizing: border-box;
  padding: 2px 2px;
}
.iKfwNr6Bx8ZjCs {
  box-sizing: border-box;
  padding: 6px 6px;
}
.pqnf8pIRk28EVz {
  box-sizing: border-box;
  padding: 4px 4px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nch-icon": `A3PtEe1rGIm_yL`,
	"nch-icon--xsmall": `Xz0CP82_7pTHvC`,
	"nch-icon--small": `neoUEAwI0GETBQ`,
	"nch-icon--medium": `J2CpPoHYfZ2U6i`,
	"nch-icon--large": `BKkTHQrUsCkzXR`,
	"nch-icon--xsmall-block": `UaKa9zr_Vk1d5U`,
	"nch-icon--small-block": `O5y03M4XzMgBNa`,
	"nch-icon--medium-block": `iKfwNr6Bx8ZjCs`,
	"nch-icon--large-block": `pqnf8pIRk28EVz`
};
export default ___CSS_LOADER_EXPORT___;
