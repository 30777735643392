// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DK66u6Halb__PB {
  padding: 16px;
  border-radius: 3px;
  background-color: var(--ds-background-danger, #ffedeb);
}
.DK66u6Halb__PB .Sg4GpcvFNdeZm_ {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  gap: 16px;
}
.DK66u6Halb__PB p {
  margin: 0 0 8px;
}
.DK66u6Halb__PB a {
  font-weight: 500;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chunkLoadErrorMessage": `DK66u6Halb__PB`,
	"row": `Sg4GpcvFNdeZm_`
};
export default ___CSS_LOADER_EXPORT___;
