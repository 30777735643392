import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { useLazyComponent } from '@trello/use-lazy-component';

import { HeaderSkeleton } from 'app/src/components/HeaderSkeleton';
import type { AuthenticatedHeaderProps } from './AuthenticatedHeader';

const Null = () => null;

export const LazyAuthenticatedHeader: FunctionComponent<
  AuthenticatedHeaderProps
> = (props) => {
  const AuthenticatedHeader = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "authenticated-header" */ './AuthenticatedHeader'
      ),
    { namedImport: 'AuthenticatedHeader' },
  );

  return (
    <ErrorBoundary
      tags={{
        ownershipArea: 'trello-web-eng',
        feature: 'Authenticated Header',
      }}
      errorHandlerComponent={Null}
    >
      <ChunkLoadErrorBoundary fallback={<HeaderSkeleton />}>
        <Suspense fallback={<HeaderSkeleton />}>
          <AuthenticatedHeader {...props} />
        </Suspense>
      </ChunkLoadErrorBoundary>
    </ErrorBoundary>
  );
};
