import {
  deleteCookie,
  getCookie,
  setStrictlyNecessaryCookie,
} from '@atlassian/browser-storage-controls';

const cookieName = 'idMember';

export let memberId: string | null;

export const getMemberIdFromCookie = (): string | null => {
  if (typeof window === 'undefined') {
    return null;
  }
  return getCookie(cookieName) || null;
};

export const getMemberId = (): string | null => {
  return memberId || null;
};

export const setMemberId = (id: string | null): void => {
  memberId = id;

  if (id) {
    setStrictlyNecessaryCookie(cookieName, id);
  } else {
    deleteCookie(cookieName);
  }
};

// eslint-disable-next-line @trello/no-module-logic
memberId = getMemberIdFromCookie();
