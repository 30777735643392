// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OTM2nTRTlYZoaY {
  margin-bottom: 24px;
}
.z26MxQmDBjk2dJ {
  font-size: 14px;
  text-transform: uppercase;
}
.tHNdQrtrv1C0bu {
  padding-left: 0;
}
.cnwf4rvS8hBIop {
  margin: 32px 0 16px 0;
}
.csSLP168aVzJo3 {
  margin-bottom: 16px;
}
.PEHhWftyVw3ZfU {
  margin-bottom: 16px;
}
.NwnzvsYtQgSiBK:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--ds-border-focused, #388bff);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `OTM2nTRTlYZoaY`,
	"step": `z26MxQmDBjk2dJ`,
	"backButton": `tHNdQrtrv1C0bu`,
	"summarySection": `cnwf4rvS8hBIop`,
	"tosSection": `csSLP168aVzJo3`,
	"submitError": `PEHhWftyVw3ZfU`,
	"submit": `NwnzvsYtQgSiBK`
};
export default ___CSS_LOADER_EXPORT___;
