// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Icl35reGK6xRbL {
  position: relative;
}
.degvI3XWjQaoRi {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}
.mT0SceVYBOWDEY {
  margin-bottom: 21px;
  font-size: 14px;
  line-height: 20px;
}
.morqIU_wY_ozB_ {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
}
.morqIU_wY_ozB_ .HMFQI9jSsHIbM1 {
  color: var(--ds-background-information-bold, #0c66e4);
}
.morqIU_wY_ozB_ .iZhomXUzFmoken {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 16px;
}
.qCTTNj4PNSwgH9 {
  position: absolute;
  right: 0;
  bottom: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PremiumTrialPaymentForm": `Icl35reGK6xRbL`,
	"title": `degvI3XWjQaoRi`,
	"description": `mT0SceVYBOWDEY`,
	"disclaimer": `morqIU_wY_ozB_`,
	"infoIcon": `HMFQI9jSsHIbM1`,
	"disclaimerDescription": `iZhomXUzFmoken`,
	"cancelButton": `qCTTNj4PNSwgH9`
};
export default ___CSS_LOADER_EXPORT___;
