// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ynl7aKrCWMCBlp {
  display: flex;
  gap: 4px;
  /* Hide default HTML checkbox */
  /* The slider */
}
.Ynl7aKrCWMCBlp .ez6roy19NLdwXG {
  margin-right: 4px;
}
.Ynl7aKrCWMCBlp .AWrzMcjfLJ5Xo7 {
  margin: 0;
  margin-right: 4px;
}
.Ynl7aKrCWMCBlp .GRCkSMM1cwcPEd,
.Ynl7aKrCWMCBlp .OJOFz9qjSF5E_B {
  font-weight: bold;
}
.Ynl7aKrCWMCBlp .gGzbHMaBhAto8z {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 16px;
  margin: 3px 4px 0 0;
}
.Ynl7aKrCWMCBlp .gGzbHMaBhAto8z:focus-within {
  border-radius: 8px;
  box-shadow: 0 0 0 2px var(--ds-border-focused, #388bff);
}
.Ynl7aKrCWMCBlp .gGzbHMaBhAto8z input {
  width: 0;
  height: 0;
  opacity: 0;
}
.Ynl7aKrCWMCBlp .MkgVOS_qsLxoce {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.4s;
  border-radius: 34px;
  background-color: var(--ds-background-accent-green-subtle, #4bce97);
  cursor: pointer;
}
.Ynl7aKrCWMCBlp .MkgVOS_qsLxoce::before {
  content: '';
  position: absolute;
  top: 2px;
  bottom: 4px;
  left: 2px;
  width: 12px;
  height: 12px;
  transition: 0.4s;
  border-radius: 50%;
  background-color: var(--ds-surface, #ffffff);
}
.Ynl7aKrCWMCBlp input:checked + .MkgVOS_qsLxoce {
  background-color: var(--ds-background-accent-green-subtle, #4bce97);
}
.Ynl7aKrCWMCBlp input:focus + .MkgVOS_qsLxoce {
  box-shadow: 0 0 1px var(--ds-background-accent-green-subtle, #4bce97);
}
.Ynl7aKrCWMCBlp input:disabled + .MkgVOS_qsLxoce {
  background-color: var(--ds-background-disabled, #091e4208);
}
.Ynl7aKrCWMCBlp input:checked + .MkgVOS_qsLxoce::before {
  transform: translateX(16px);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleWrapper": `Ynl7aKrCWMCBlp`,
	"primaryLabel": `ez6roy19NLdwXG`,
	"label": `AWrzMcjfLJ5Xo7`,
	"leftLabel": `GRCkSMM1cwcPEd`,
	"rightLabel": `OJOFz9qjSF5E_B`,
	"switch": `gGzbHMaBhAto8z`,
	"slider": `MkgVOS_qsLxoce`
};
export default ___CSS_LOADER_EXPORT___;
