// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FZFWsjbSjLEt1s {
  color: var(--ds-text-subtle, #44546f);
  font-weight: 400;
}
.eVTfsc3M5wqN6K {
  margin-bottom: 4px;
  color: var(--ds-text-danger, #ae2a19);
  font-weight: 700;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tosLabel": `FZFWsjbSjLEt1s`,
	"error": `eVTfsc3M5wqN6K`
};
export default ___CSS_LOADER_EXPORT___;
