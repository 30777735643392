import { from } from '@apollo/client';

import { createAtlassianHttpLink } from './links/atlassianHttpLink';
import { handleUnauthorizedTokenLink } from './links/handleUnauthorizedTokenLink';

// We export this function mostly to help with testing.
export function createTrelloLinkChain() {
  return from([handleUnauthorizedTokenLink, createAtlassianHttpLink()]);
}

// eslint-disable-next-line @trello/no-module-logic
export const trelloLinkChain = createTrelloLinkChain();
