import isEqual from 'react-fast-compare';

import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';
import type {
  FeatureFlagUser,
  SupportedFlagTypes,
} from '@atlassiansox/feature-flag-web-client';
import AtlassianFeatureFlagClient, {
  EnvironmentType,
  Identifiers,
} from '@atlassiansox/feature-flag-web-client';
import { Analytics } from '@trello/atlassian-analytics';
import { getMemberId } from '@trello/authentication';
import { isDesktop, isTouch } from '@trello/browser';
import {
  atlassianFeatureFlagClientKey,
  environment,
  locale,
} from '@trello/config';
import { TrelloStorage } from '@trello/storage';

import {
  featureFlags,
  type RegisteredFeatureFlagKey,
} from './data/featureFlags';

const USER_DATA_CACHE_TIMEOUT = 24 * 60 * 60 * 1000;
const USER_DATA_CACHE_STORAGE_KEY = 'featureFlagUserData';
const OVERRIDES_STORAGE_KEY = 'featureFlagOverrides';
const EXPOSURE_EVENTS_RATE = 60 * 60 * 1000; // max one event per hour

/**
 * Given a flag key, return the default value for that flag.
 * @param flag key for the feature flag
 * @returns the default value for the feature flag
 */
const getDefaultFlagValue = (flag: RegisteredFeatureFlagKey) =>
  featureFlags[flag].defaultValue;

/**
 * Type guard to check if a string is a valid feature flag key.
 * @param flag potential feature flag key
 * @returns Type guard for feature flag keys
 */
const isRegisteredFeatureFlagKey = (
  flag: string,
): flag is RegisteredFeatureFlagKey => flag in featureFlags;

export const getExposureEventsStorageKey = () =>
  `featureFlagExposureEvents-${getMemberId() || 'anonymous'}`;

interface UserData {
  idEnterprises: string[];
  idOrgs: string[];
  orgs: string[];
  emailDomain: string;
  clientVersion: string;
  hasBC: boolean;
  hasMultipleEmails: boolean;
  head: string;
  inEnterprise: boolean;
  isClaimable: boolean;
  products: number[];
  premiumFeatures: string[];
  version: number;
  // LD dates must be formatted as UNIX milliseconds
  // https://docs.launchdarkly.com/home/managing-flags/targeting-users#date-comparisons
  signupDate: number | undefined;
}

interface UserDataCache {
  lastUpdated: number;
  key: string | null;
  data: UserData;
}

interface ExposureEvents {
  [key: string]:
    | {
        value?: SupportedFlagTypes;
        reason?: string;
        ruleId?: string;
        timeSent?: number;
      }
    | undefined;
}

interface ChangeListener<T> {
  (current?: T, previous?: T): void;
}

type ChangeListenerMap = {
  [key in RegisteredFeatureFlagKey]: ChangeListener<SupportedFlagTypes>[];
};

type UnsubscriberMap = {
  [key in RegisteredFeatureFlagKey]: () => void;
};

export type FlagSet = {
  [key in RegisteredFeatureFlagKey]: SupportedFlagTypes;
};

interface FeatureFlagGroups {
  remote: FlagSet;
  overrides: FlagSet;
}

export class FeatureFlagClient {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private atlassianClientUser: FeatureFlagUser;
  private atlassianClientUnsubscribers = {} as UnsubscriberMap;
  private changeListeners = {} as ChangeListenerMap;
  private flagReadsSubscriber: ((key: string) => void) | null = null;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public atlassianClient: AtlassianFeatureFlagClient;
  public pollingInterval: number = 5000;

  createInitialUser() {
    const memberId = getMemberId();
    const identifierValue = memberId;

    // Build up our flag user: we want to omit the `identifier` if the memberId
    // is not set, so that the client will generate a UUID
    this.atlassianClientUser = {};
    if (identifierValue !== null) {
      this.atlassianClientUser.identifier = {
        type: Identifiers.TRELLO_USER_ID,
        value: identifierValue,
      };
    }

    // Add the attributes we know are present at startup
    this.atlassianClientUser.custom = {
      isDesktop: isDesktop(),
      isTouch: isTouch(),
      locale,
    };

    // Attempt to load cached user information, and hydrate it if we are the same
    // user
    const userData: UserDataCache = TrelloStorage.get(
      USER_DATA_CACHE_STORAGE_KEY,
    );
    const isUserDataExpired =
      userData && Date.now() - userData.lastUpdated > USER_DATA_CACHE_TIMEOUT;
    const sameUser = userData?.key && userData.key === memberId;

    if (userData) {
      if (!isUserDataExpired && sameUser) {
        this.atlassianClientUser = {
          ...this.atlassianClientUser,
          custom: {
            ...this.atlassianClientUser.custom,
            ...userData.data,
          },
        };
      } else {
        TrelloStorage.unset(USER_DATA_CACHE_STORAGE_KEY);
      }
    }
  }

  initializeAtlassianClient() {
    let clientEnv = EnvironmentType.DEV;
    if (environment === 'staging') {
      clientEnv = EnvironmentType.STAGING;
    } else if (environment === 'prod') {
      clientEnv = EnvironmentType.PROD;
    }

    // Poll at 5 minutes in prod, 5 seconds otherwise
    this.pollingInterval =
      clientEnv === EnvironmentType.PROD ? 1000 * 60 * 5 : 5000;

    this.atlassianClient = new AtlassianFeatureFlagClient(
      atlassianFeatureFlagClientKey,
      Analytics,
      this.atlassianClientUser,
      {
        productKey: 'trello',
        environment: clientEnv, // Would be nice if we didn't need to provide this
        pollingInterval: this.pollingInterval,
        loggerOptions: {
          enabled: false,
        },
      },
    );
  }

  initializePlatformFeatureFlags() {
    setBooleanFeatureFlagResolver((key) =>
      this.get<boolean>(key as RegisteredFeatureFlagKey, false),
    );
  }

  constructor() {
    // Create the initial feature flag user
    this.createInitialUser();

    // Initialize the fx3 client
    this.initializeAtlassianClient();

    // Initialize platform feature flags
    this.initializePlatformFeatureFlags();
  }

  subscribeToFlagReads(callback: (key: string) => void) {
    this.flagReadsSubscriber = callback;

    return {
      unsubscribe: () => {
        this.flagReadsSubscriber = null;
      },
    };
  }

  refineUserData(data: UserData) {
    const cacheData: UserDataCache = {
      lastUpdated: Date.now(),
      key: getMemberId(),
      data,
    };

    TrelloStorage.set(USER_DATA_CACHE_STORAGE_KEY, cacheData);

    return this.atlassianClient.updateFeatureFlagUser({
      ...this.atlassianClientUser,
      custom: {
        ...this.atlassianClientUser.custom,
        ...data,
      },
    });
  }

  get = <T extends SupportedFlagTypes>(
    key: RegisteredFeatureFlagKey,
    defaultValue: T,
  ): T => {
    if (defaultValue === undefined && process.env.NODE_ENV === 'development') {
      console.warn(
        `Flag evaluation now requires a default value as the second argument to .get(). Please ensure a default value as the second argument when evaluating ${key}`,
      );
    }

    this.flagReadsSubscriber?.(key);

    const override = this.getOverride(key);

    if (typeof override !== 'undefined') {
      Analytics.setFlagEvaluation(key, override);
      return override as T;
    }

    const evaluatedFlagValue = this.atlassianClient.getFlagValue(
      key,
      defaultValue,
    );
    Analytics.setFlagEvaluation(key, evaluatedFlagValue);
    return evaluatedFlagValue;
  };

  getTrackedVariation = <T extends SupportedFlagTypes>(
    key: RegisteredFeatureFlagKey,
    defaultValue: T,
    attributes?: object,
    cacheKeySuffix?: string,
  ): T => {
    const override = this.getOverride(key);

    if (typeof override !== 'undefined') {
      this.triggerExposureEvent(
        key,
        override,
        'OVERRIDE',
        undefined,
        attributes,
        cacheKeySuffix,
      );
      return override as T;
    }

    const evaluatedFlag = this.atlassianClient.getFlagDetails(
      key,
      defaultValue,
    );
    const value = evaluatedFlag.value;
    const evaluationDetail = evaluatedFlag.evaluationDetail;
    if (evaluationDetail) {
      const kind = evaluationDetail.reason;
      const ruleId = evaluationDetail.ruleId;

      this.triggerExposureEvent(
        key,
        value,
        kind,
        ruleId,
        attributes,
        cacheKeySuffix,
      );
    }

    Analytics.setFlagEvaluation(key, value);
    return value;
  };

  all(): FeatureFlagGroups {
    const overrides: FlagSet = TrelloStorage.get(OVERRIDES_STORAGE_KEY) || {};

    // Get the raw flags from atlassianClient
    const atlassianClientFlags = this.atlassianClient.getFlags();

    // Pluck the values off the flags (they come with some additional stuff we don't
    // care about here)
    const remote = {} as FlagSet;
    for (const [key, { value }] of Object.entries(atlassianClientFlags)) {
      remote[key as RegisteredFeatureFlagKey] = value;
    }

    return { remote, overrides };
  }

  public triggerExposureEvent = (
    flagKey: string,
    value: SupportedFlagTypes,
    reason: string,
    ruleId?: string,
    attributes?: object,
    cacheKeySuffix?: string,
  ) => {
    const EXPOSURE_EVENTS_STORAGE_KEY = getExposureEventsStorageKey();

    const exposureEvents: ExposureEvents =
      TrelloStorage.get(EXPOSURE_EVENTS_STORAGE_KEY) || {};

    // cacheKeySuffix can be used to help differentiate between exposure events with different attributes for the same flag.
    // Exposure events will be triggered if flag suffixed with cacheKeySuffix is not already in localStorage.
    const storageFlagKey = `${flagKey}${
      cacheKeySuffix ? `/${cacheKeySuffix}` : ''
    }`;

    const lastEvent = exposureEvents[storageFlagKey];

    // Don't trigger exposure events more than once per hour
    if (
      lastEvent?.timeSent &&
      Date.now() < lastEvent.timeSent + EXPOSURE_EVENTS_RATE
    ) {
      return;
    }

    // Don't trigger exposure events if the evaluation details haven't changed
    if (
      lastEvent &&
      isEqual(value, lastEvent.value) &&
      reason === lastEvent.reason &&
      ruleId === lastEvent.ruleId
    ) {
      return;
    }

    // Filter out any localStorage data referring to flags that no longer exist
    const { remote } = this.all();
    const validExposureEvents: ExposureEvents = {};
    for (const key of Object.keys(exposureEvents)) {
      if (Object.prototype.hasOwnProperty.call(remote, key)) {
        validExposureEvents[key] = exposureEvents[key];
      }
    }

    // Update the localStorage data with the exposure event we're about to send
    const updatedExposureEvents: ExposureEvents = {
      ...validExposureEvents,
      [storageFlagKey]: {
        timeSent: Date.now(),
        value,
        reason,
        ruleId,
      },
    };

    TrelloStorage.set(EXPOSURE_EVENTS_STORAGE_KEY, updatedExposureEvents);

    Analytics.sendTrackEvent({
      source: '@trello/feature-flag-client',
      actionSubject: 'feature',
      action: 'exposed',
      attributes: {
        flagKey,
        reason,
        ruleId,
        value,
        ...attributes,
      },
    });
  };

  private createClientListener(
    key: RegisteredFeatureFlagKey,
  ): ChangeListener<SupportedFlagTypes> {
    const clientListener = (
      current: SupportedFlagTypes,
      previous: SupportedFlagTypes,
    ) => {
      if (Object.prototype.hasOwnProperty.call(this.changeListeners, key)) {
        if (typeof this.getOverride(key) === 'undefined') {
          for (const listener of this.changeListeners[key]) {
            listener(current, previous);
          }
        } else {
          console.warn(
            `Ignoring feature flag change event for "${key}" from server due to local override`,
          );
        }
      }
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return clientListener;
  }

  on = <T extends SupportedFlagTypes>(
    key: RegisteredFeatureFlagKey,
    defaultValue: T,
    callback: ChangeListener<T>,
  ) => {
    if (Object.prototype.hasOwnProperty.call(this.changeListeners, key)) {
      this.changeListeners[key].push(
        callback as ChangeListener<SupportedFlagTypes>,
      );
    } else {
      // Create the first change listener
      this.changeListeners[key] = [
        callback as ChangeListener<SupportedFlagTypes>,
      ];

      const unsubscriber = this.atlassianClient.on(
        key,
        defaultValue,
        this.createClientListener(key),
      );
      this.atlassianClientUnsubscribers[key] = unsubscriber;
    }
  };

  off = <T extends SupportedFlagTypes>(
    key: RegisteredFeatureFlagKey,
    callback: ChangeListener<T>,
  ) => {
    if (Object.prototype.hasOwnProperty.call(this.changeListeners, key)) {
      const idx = this.changeListeners[key].indexOf(
        callback as ChangeListener<SupportedFlagTypes>,
      );

      if (idx > -1) {
        this.changeListeners[key].splice(idx, 1);
      }

      if (this.changeListeners[key].length === 0) {
        // Unsubscribe from atlassianClient
        this.atlassianClientUnsubscribers[key]?.();
        delete this.atlassianClientUnsubscribers[key];
      }
    }
  };

  ready = () => {
    return this.atlassianClient.ready();
  };

  private getOverride(
    key: RegisteredFeatureFlagKey,
  ): SupportedFlagTypes | undefined {
    const overrides: FlagSet = TrelloStorage.get(OVERRIDES_STORAGE_KEY);
    if (overrides && Object.prototype.hasOwnProperty.call(overrides, key)) {
      return overrides[key];
    }
  }

  setOverride<T extends SupportedFlagTypes>(
    key: RegisteredFeatureFlagKey,
    value: T,
    defaultValue: T = value,
  ): void {
    const originalValue = this.get(key, defaultValue);
    const existingOverrides: FlagSet =
      TrelloStorage.get(OVERRIDES_STORAGE_KEY) || {};

    TrelloStorage.set(OVERRIDES_STORAGE_KEY, {
      ...existingOverrides,
      [key]: value,
    });

    if (Object.prototype.hasOwnProperty.call(this.changeListeners, key)) {
      // Call all change listeners for this feature flag
      for (const listener of this.changeListeners[key]) {
        listener(value, originalValue);
      }
    }
  }

  removeOverride(key: RegisteredFeatureFlagKey): void {
    const defaultValue = getDefaultFlagValue(key);
    const originalValue = this.get(key, defaultValue);
    const existingOverrides: FlagSet =
      TrelloStorage.get(OVERRIDES_STORAGE_KEY) || {};

    delete existingOverrides[key];

    TrelloStorage.set(OVERRIDES_STORAGE_KEY, existingOverrides);

    if (Object.prototype.hasOwnProperty.call(this.changeListeners, key)) {
      // Call all change listeners for this feature flag
      for (const listener of this.changeListeners[key]) {
        listener(this.get(key, defaultValue), originalValue);
      }
    }
  }

  resetOverrides(): void {
    const overrides: FlagSet = TrelloStorage.get(OVERRIDES_STORAGE_KEY) || {};
    TrelloStorage.set(OVERRIDES_STORAGE_KEY, {});

    // Call change listeners for all overrides that were just reset
    Object.entries(overrides).forEach(([key, originalValue]) => {
      if (
        isRegisteredFeatureFlagKey(key) &&
        Object.prototype.hasOwnProperty.call(this.changeListeners, key)
      ) {
        for (const listener of this.changeListeners[key]) {
          const defaultValue = getDefaultFlagValue(key);
          listener(this.get(key, defaultValue), originalValue);
        }
      }
    });
  }
}

// eslint-disable-next-line @trello/no-module-logic
export const featureFlagClient = new FeatureFlagClient();
