import { SharedState } from '@trello/shared-state';

import type { QuickLoadOperations } from './operation-to-quickload-url.generated';

export const quickLoadSharedState = new SharedState<{
  isLoading: boolean;
  routeToLoadingState: Record<QuickLoadOperations, boolean>;
}>({
  isLoading: true,
  routeToLoadingState: {
    MemberHeader: false,
    MemberBoardsHome: false,
    MemberBoards: false,
    CurrentBoardInfo: false,
    CurrentBoardListsCards: false,
    TrelloCurrentBoardInfo: false,
    MemberQuickBoards: false,
    QuickBoardsSearch: false,
    PreloadCard: false,
    OrganizationBillingPage: false,
    WorkspaceBoardsPageMinimal: false,
    WorkspaceHomePageMinimal: false,
  },
});
