import {
  cs,
  csWithoutTime,
  de,
  deWithoutTime,
  en,
  enWithoutTime,
  es,
  esWithoutTime,
  fi,
  fiWithoutTime,
  fr,
  frWithoutTime,
  hu,
  huWithoutTime,
  it,
  itWithoutTime,
  ja,
  jaWithoutTime,
  nb,
  nbWithoutTime,
  nl,
  nlWithoutTime,
  pl,
  plWithoutTime,
  pt,
  ptWithoutTime,
  ru,
  ruWithoutTime,
  sv,
  svWithoutTime,
  th,
  thWithoutTime,
  tr,
  trWithoutTime,
  uk,
  ukWithoutTime,
  vi,
  viWithoutTime,
  zhHans,
  zhHansWithoutTime,
  zhHant,
  zhHantWithoutTime,
} from './localeRelativeFormats';
import type { RelativeFormatConfig } from './relativeFormatConfig';

/**
 * Date formats for each locale used for parsing.
 */
export const relativeFormats: Record<string, RelativeFormatConfig> = {
  cs,
  de,
  'en-AU': en,
  'en-GB': en,
  'en-US': en,
  es,
  fi,
  fr,
  'fr-CA': fr,
  hu,
  it,
  ja,
  nb,
  nl,
  pl,
  'pt-BR': pt,
  ru,
  sv,
  th,
  tr,
  uk,
  vi,
  'zh-Hans': zhHans,
  'zh-Hant': zhHant,
};

/**
 * Date formats for each locale used for parsing.
 */
export const relativeFormatsWithoutTime: Record<string, RelativeFormatConfig> =
  {
    cs: csWithoutTime,
    de: deWithoutTime,
    'en-AU': enWithoutTime,
    'en-GB': enWithoutTime,
    'en-US': enWithoutTime,
    es: esWithoutTime,
    fi: fiWithoutTime,
    fr: frWithoutTime,
    'fr-CA': frWithoutTime,
    hu: huWithoutTime,
    it: itWithoutTime,
    ja: jaWithoutTime,
    nb: nbWithoutTime,
    nl: nlWithoutTime,
    pl: plWithoutTime,
    'pt-BR': ptWithoutTime,
    ru: ruWithoutTime,
    sv: svWithoutTime,
    th: thWithoutTime,
    tr: trWithoutTime,
    uk: ukWithoutTime,
    vi: viWithoutTime,
    'zh-Hans': zhHansWithoutTime,
    'zh-Hant': zhHantWithoutTime,
  };
