import { forTemplate } from '@trello/legacy-i18n';

const format = forTemplate('credit_card');

const localizeOption = ([countryCode, localizationKey]: string[]): string[] => [
  countryCode,
  format(['countries', localizationKey]),
];

export const countries = {
  // eslint-disable-next-line @trello/no-module-logic
  special: [
    ['US', 'united-states'],
    ['GB', 'united-kingdom'],
    ['CA', 'canada'],
    ['AU', 'australia'],
    ['DE', 'germany'],
    ['NL', 'netherlands'],
  ].map(localizeOption),
  // eslint-disable-next-line @trello/no-module-logic
  unloved: [
    ['AF', 'afghanistan'],
    ['AX', 'aland'],
    ['AL', 'albania'],
    ['DZ', 'algeria'],
    ['AS', 'american-samoa'],
    ['AD', 'andorra'],
    ['AO', 'angola'],
    ['AI', 'anguilla'],
    ['AQ', 'antarctica'],
    ['AG', 'antiqua-and-barbuda'],
    ['AR', 'argentina'],
    ['AM', 'armenia'],
    ['AW', 'aruba'],
    ['AU', 'australia'],
    ['AT', 'austria'],
    ['AZ', 'azerbaijan'],
    ['BS', 'bahamas'],
    ['BH', 'bahrain'],
    ['BD', 'bangladesh'],
    ['BB', 'barbados'],
    ['BE', 'belgium'],
    ['BZ', 'belize'],
    ['BJ', 'benin'],
    ['BM', 'bermuda'],
    ['BT', 'bhutan'],
    ['BO', 'bolivia'],
    ['BQ', 'bonaire-sint-eustatius-and-saba'],
    ['BA', 'bosnia-herzegovina'],
    ['BW', 'botswana'],
    ['BV', 'bouvet-islands'],
    ['BR', 'brazil'],
    ['IO', 'british-indian-ocean-territory'],
    ['VG', 'british-virgin-islands'],
    ['BN', 'brunei'],
    ['BG', 'bulgaria'],
    ['BF', 'burkina-faso'],
    ['BI', 'burundi'],
    ['KH', 'cambodia'],
    ['CM', 'cameroon'],
    ['CA', 'canada'],
    ['IC', 'canary-islands'],
    ['CV', 'cape-verde'],
    ['KY', 'cayman-islands'],
    ['CF', 'central-african-republic'],
    ['TD', 'chad'],
    ['CL', 'chile'],
    ['CN', 'china'],
    ['CX', 'christmas-island'],
    ['CC', 'cocos-islands'],
    ['CO', 'colombia'],
    ['KM', 'comoros'],
    ['CG', 'congo'],
    ['CK', 'cook-islands'],
    ['CR', 'costa-rica'],
    ['CI', 'cote-d-ivoire'],
    ['HR', 'croatia'],
    ['CW', 'curacao'],
    ['CY', 'cyprus'],
    ['CZ', 'czech-republic'],
    ['DK', 'denmark'],
    ['DJ', 'djibouti'],
    ['DM', 'dominica'],
    ['DO', 'dominican-republic'],
    ['EG', 'egypt'],
    ['SV', 'el-salvador'],
    ['EC', 'equador'],
    ['GQ', 'equatorial-guinea'],
    ['ER', 'eritrea'],
    ['EE', 'estonia'],
    ['ET', 'ethiopia'],
    ['FK', 'falkland-islands'],
    ['FO', 'faroe-islands'],
    ['FM', 'federated-states-of-micronesia'],
    ['FJ', 'fiji'],
    ['FI', 'finland'],
    ['FR', 'france'],
    ['GF', 'french-guiana'],
    ['PF', 'french-polynesia'],
    ['TF', 'french-southern-lands'],
    ['GA', 'gabon'],
    ['GM', 'gambia'],
    ['GE', 'georgia'],
    ['DE', 'germany'],
    ['GH', 'ghana'],
    ['GI', 'gibraltar'],
    ['GR', 'greece'],
    ['GL', 'greenland'],
    ['GD', 'grenada'],
    ['GP', 'guadeloupe'],
    ['GU', 'guam'],
    ['GT', 'guatemala'],
    ['GG', 'guernsey'],
    ['GW', 'guinea-bissau'],
    ['GN', 'guinea'],
    ['GY', 'guyana'],
    ['HT', 'haiti'],
    ['HM', 'heard-and-mcdonald-islands'],
    ['HN', 'honduras'],
    ['HK', 'hong-kong'],
    ['HU', 'hungary'],
    ['IS', 'iceland'],
    ['IN', 'india'],
    ['ID', 'indonesia'],
    ['IQ', 'iraq'],
    ['IE', 'ireland'],
    ['IM', 'isle-of-man'],
    ['IL', 'israel'],
    ['IT', 'italy'],
    ['JM', 'jamaica'],
    ['JP', 'japan'],
    ['JE', 'jersey'],
    ['JO', 'jordan'],
    ['KZ', 'kazakhstan'],
    ['KE', 'kenya'],
    ['KI', 'kiribati'],
    ['KW', 'kuwait'],
    ['KG', 'kyrgyzstan'],
    ['LA', 'laos'],
    ['LV', 'latvia'],
    ['LB', 'lebanon'],
    ['LS', 'lesotho'],
    ['LR', 'liberia'],
    ['LY', 'libya'],
    ['LI', 'liechtenstein'],
    ['LT', 'lithuania'],
    ['LU', 'luxembourg'],
    ['MO', 'macau'],
    ['MG', 'madagascar'],
    ['MW', 'malawi'],
    ['MY', 'malaysia'],
    ['MV', 'maldives'],
    ['ML', 'mali'],
    ['MT', 'malta'],
    ['MH', 'marshall-islands'],
    ['MQ', 'martinique'],
    ['MR', 'mauritania'],
    ['MU', 'mauritius'],
    ['YT', 'mayotte'],
    ['MX', 'mexico'],
    ['MD', 'moldova'],
    ['MC', 'monaco'],
    ['MN', 'mongolia'],
    ['ME', 'montenegro'],
    ['MS', 'montserrat'],
    ['MA', 'morocco'],
    ['MZ', 'mozambique'],
    ['MM', 'myanmar'],
    ['NA', 'namibia'],
    ['NR', 'nauru'],
    ['NP', 'nepal'],
    ['NL', 'netherlands'],
    ['NC', 'new-caledonia'],
    ['NZ', 'new-zealand'],
    ['NI', 'nicaragua'],
    ['NE', 'niger'],
    ['NG', 'nigeria'],
    ['NU', 'niue'],
    ['NF', 'norfolk-island'],
    ['MP', 'northern-mariana-islands'],
    ['NO', 'norway'],
    ['OM', 'oman'],
    ['PK', 'pakistan'],
    ['PW', 'palau'],
    ['PS', 'palestine'],
    ['PA', 'panama'],
    ['PG', 'papua-new-guinea'],
    ['PY', 'paraguay'],
    ['PE', 'peru'],
    ['PH', 'philippines'],
    ['PN', 'pitcairn'],
    ['PL', 'poland'],
    ['PT', 'portugal'],
    ['PR', 'puerto-rico'],
    ['QA', 'qatar'],
    ['KR', 'republic-of-korea'],
    ['RE', 'reunion'],
    ['RO', 'romania'],
    ['RW', 'rwanda'],
    ['BL', 'saint-barthelemy'],
    ['MF', 'saint-martin'],
    ['PM', 'saint-pierre-and-miquelon'],
    ['WS', 'samoa'],
    ['SM', 'san-marino'],
    ['ST', 'sao-tome-and-principe'],
    ['SA', 'saudi-arabia'],
    ['SN', 'senegal'],
    ['RS', 'serbia'],
    ['SC', 'seychelles'],
    ['SL', 'sierra-leone'],
    ['SG', 'singapore'],
    ['SX', 'sint-maarten'],
    ['SK', 'slovakia'],
    ['SI', 'slovenia'],
    ['SB', 'solomon-islands'],
    ['SO', 'somalia'],
    ['ZA', 'south-africa'],
    ['GS', 'south-georgia-and-south-sandwich-islands'],
    ['SS', 'south-sudan'],
    ['ES', 'spain'],
    ['LK', 'sri-lanka'],
    ['SH', 'st-helena'],
    ['KN', 'st-kitts-and-nevis'],
    ['LC', 'st-lucia'],
    ['VC', 'st-vincent-and-the-grenadines'],
    ['SD', 'sudan'],
    ['SR', 'suriname'],
    ['SJ', 'svalbard-and-jan-mayen-islands'],
    ['SZ', 'swaziland'],
    ['SE', 'sweden'],
    ['CH', 'switzerland'],
    ['TW', 'taiwan'],
    ['TJ', 'tajikistan'],
    ['TZ', 'tanzania'],
    ['TH', 'thailand'],
    ['MK', 'the-former-yugoslav-republic-of-macedonia'],
    ['TL', 'timor-leste'],
    ['TG', 'togo'],
    ['TK', 'tokelau'],
    ['TO', 'tonga'],
    ['TT', 'trinidad-and-tobago'],
    ['TN', 'tunisia'],
    ['TR', 'turkey'],
    ['TM', 'turkmenistan'],
    ['TC', 'turks-and-caicos-islands'],
    ['TV', 'tuvalu'],
    ['UG', 'uganda'],
    ['UA', 'ukraine'],
    ['AE', 'united-arab-emirates'],
    ['GB', 'united-kingdom'],
    ['US', 'united-states'],
    ['UY', 'uruguay'],
    ['UZ', 'uzbekistan'],
    ['VU', 'vanuatu'],
    ['VA', 'vatican-city'],
    ['VE', 'venezuela'],
    ['VN', 'vietnam'],
    ['VI', 'virgin-islands-us'],
    ['WF', 'wallis-and-futuna-islands'],
    ['EH', 'western-sahara'],
    ['YE', 'yemen'],
    ['CD', 'zaire'],
    ['ZM', 'zambia'],
    ['ZW', 'zimbabwe'],
  ]
    .map(localizeOption)
    .sort(([, aName], [, bName]) => aName.localeCompare(bName)),
  // eslint-disable-next-line @trello/no-module-logic
  sanctioned: [
    ['BY', 'belarus'],
    ['RU', 'russia'],
  ].map(localizeOption),
};

export const getCountryName = (countryCode: string): string | null => {
  const item = countries.unloved.find(([code]) => countryCode === code);
  return item?.[1] ?? null;
};

export const euCountries = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'GR',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
];

export const usTerritories = ['PR', 'AS', 'FM', 'GU', 'MH', 'MP', 'PW', 'VI'];

export const sanctionedCountries = ['RU', 'BY'];
