import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { trelloFetch } from '@trello/fetch';
import { parseNetworkError } from '@trello/graphql-error-handling';
import { getNetworkClient } from '@trello/network-client';
import { dangerouslyConvertPrivacyString } from '@trello/privacy';

import type { QueryTemplateGalleryArgs } from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import type { TrelloRestResolver, TypedJSONObject } from '../types';

export const templateCategoriesResolver: TrelloRestResolver<unknown> = async (
  _parent,
  _args,
  context,
  info,
): Promise<TypedJSONObject> => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/resources/templates/categories`);

  let response;
  try {
    response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'templateCategories',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      model = await response.json();
      return model ? prepareDataForApolloCache(model, rootNode) : model;
    }

    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
  } catch (err) {
    console.error(err);
    throw new Error(
      `An error occurred while fetching/parsing template categories GraphQL query. (error: ${err}, status: ${response?.status}, statusText: ${response?.statusText}`,
    );
  }
  throw await parseNetworkError(response);
};

export const templateLanguagesResolver: TrelloRestResolver<unknown> = async (
  _parent,
  _args,
  context,
  info,
): Promise<TypedJSONObject> => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    '/1/resources/templates/languages?enabled=true',
  );

  let response;

  try {
    response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'templateLanguages',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      model = await response.json();
      return model ? prepareDataForApolloCache(model, rootNode) : model;
    }
  } catch (err) {
    console.error(err);
    throw new Error(
      `An error occurred while fetching/parsing template languages GraphQL query. ${{
        error: err,
        response,
      }}`,
    );
  }

  throw new Error(
    `An error occurred while resolving template languages GraphQL query. (status: ${response?.status}, statusText: ${response?.statusText})`,
  );
};

export const templateGalleryResolver: TrelloRestResolver<
  QueryTemplateGalleryArgs
> = async (_parent, args, context, info): Promise<TypedJSONObject> => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/boards/templates/gallery?locale=${
      dangerouslyConvertPrivacyString(args.locale) || 'en'
    }`,
  );

  let response;

  try {
    response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'templateGallery',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      model = await response.json();
      return model ? prepareDataForApolloCache(model, rootNode) : model;
    }

    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
  } catch (err) {
    console.error(err);
    throw new Error(
      `An error occurred while fetching/parsing template gallery GraphQL query.(error: ${err}, status: ${response?.status}, statusText: ${response?.statusText}`,
    );
  }
  throw await parseNetworkError(response);
};
