/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { logoDomain, siteDomain } from '@trello/config';

import { Auth } from 'app/scripts/db/Auth';
import { MenuBaseTemplate } from 'app/scripts/views/templates/MenuBaseTemplate';
import { teacupWithHelpers } from './teacupWithHelpers';

const t = teacupWithHelpers();

const methods = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mustacheRenderWithHelpers(body: any, data: any, opts?: any, partials?: any) {
    if (opts == null) {
      opts = {};
    }
    if (partials == null) {
      partials = {};
    }
    const args = {
      __ed: opts.editable,
      __own: opts.owned,
      __loggedIn: Auth.isLoggedIn(),
      __siteDomain: siteDomain,
      __logoDomain: logoDomain,
      ...data,
    };

    return t.mustacheRender(body, args, partials);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mustacheRenderModel(body: any, model: any, data: any, partials?: any) {
    if (partials == null) {
      partials = {};
    }
    const opts = {
      editable:
        typeof model.editable === 'function' ? model.editable() : undefined,
      owned: typeof model.owned === 'function' ? model.owned() : undefined,
    };
    data = { ...model.toJSON(), ...data };
    return methods.mustacheRenderWithHelpers(body, data, opts, partials);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fill(template: any, data?: any, opts?: any, partials?: any) {
    if (data == null) {
      data = {};
    }
    if (opts == null) {
      opts = {};
    }
    if (partials == null) {
      partials = {};
    }
    if (typeof template === 'string') {
      throw new Error(
        `You must now pass in an actual template, not a string. This makes our dependency tree more correct. The template we received was '${template}'`,
      );
    }
    return methods.mustacheRenderWithHelpers(template, data, opts, partials);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fillFromModel(template: any, model: any, data?: any, partials?: any) {
    if (data == null) {
      data = {};
    }
    if (partials == null) {
      partials = {};
    }
    if (typeof template === 'string') {
      throw new Error(
        `You must now pass in an actual template, not a string. This makes our dependency tree more correct. The template we received was '${template}'`,
      );
    }
    return methods.mustacheRenderModel(template, model, data, partials);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fillMenu(template: any, data: any, opts: any) {
    return methods.fill(MenuBaseTemplate, {
      content: this.fill(template, data, opts),
    });
  },
};

export { methods as templates };
