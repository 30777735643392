import { Analytics } from '@trello/atlassian-analytics';
import { fetch } from '@trello/fetch';
import { getNetworkClient } from '@trello/network-client';
import { getCsrfRequestPayload } from '@trello/session-cookie/csrf';

import type {
  MutationCreateLabelArgs,
  MutationDeleteLabelArgs,
  MutationUpdateLabelArgs,
} from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import type { TrelloRestResolver } from '../types';

export const createLabel: TrelloRestResolver<MutationCreateLabelArgs> = async (
  obj,
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const networkClient = getNetworkClient();
  const { name, color, idBoard, idCard, traceId } = args;

  // If idCard is provided, make the request to the `card` endpoint to add the
  // label to the given card after creation.
  const scope = idCard ? 'card' : 'board';
  const idScope = idCard || idBoard;

  const response = await fetch(
    networkClient.getUrl(`/1/${scope}/${idScope}/labels`),
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
        ...Analytics.getTaskRequestHeaders(traceId),
      },
      body: JSON.stringify({
        color,
        name,
        idBoard,
        ...getCsrfRequestPayload(),
      }),
    },
  );

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

  const label = await response.json();

  return prepareDataForApolloCache(label, rootNode);
};

export const deleteLabel: TrelloRestResolver<MutationDeleteLabelArgs> = async (
  obj,
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const networkClient = getNetworkClient();
  const response = await fetch(networkClient.getUrl(`/1/labels/${args.id}`), {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(args.traceId, trelloServerVersion);

  await response.json();

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const updateLabel: TrelloRestResolver<MutationUpdateLabelArgs> = async (
  obj,
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/labels/${args.label.id}`),
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
        ...Analytics.getTaskRequestHeaders(args.traceId),
      },
      body: JSON.stringify({
        name: args.label.name ?? '',
        color: args.label.color ?? 'null',
        ...getCsrfRequestPayload(),
      }),
    },
  );

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(args.traceId, trelloServerVersion);

  const label = await response.json();

  return prepareDataForApolloCache(label, rootNode);
};
