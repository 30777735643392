import type { Member } from '@trello/model-types';

/**
 * Checks if a user has an Enterprise License.
 * A user is considered to have a license when:
 *  - The user has an idEnterprise
 *  - The type of license is either "team" or "collaborator"
 */
export const isLicensedEnterpriseMember = (member: {
  enterpriseLicenses?:
    | Pick<Member['enterpriseLicenses'][number], 'type'>[]
    | null;
}): boolean => {
  if (!member.enterpriseLicenses) {
    return false;
  }
  return Boolean(
    member.enterpriseLicenses.filter((license) => {
      return license.type === 'team' || license.type === 'collaborator';
    }).length > 0,
  );
};
