import { trelloFetch } from '@trello/fetch';
import { getNetworkClient } from '@trello/network-client';

import type { QueryQrCodeArgs } from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import type { TrelloRestResolver } from '../types';

export const qrCodeResolver: TrelloRestResolver<QueryQrCodeArgs> = async (
  _parent,
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl('/1/share/qrcode');
  const url = encodeURIComponent(args.url);
  const response = await trelloFetch(
    `${apiUrl}?url=${url}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Board_History.cardsPerList',
        operationName: context.operationName,
      },
    },
  );
  const qrCode = await response.json();

  return prepareDataForApolloCache(
    { imageData: qrCode._value || '' },
    rootNode,
  );
};
