// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dfo16Mq4yBSdk9 {
  margin-bottom: 6px;
  border-collapse: collapse;
}
.Dfo16Mq4yBSdk9,
.Dfo16Mq4yBSdk9 tr,
.Dfo16Mq4yBSdk9 td {
  border: none;
}
.Dfo16Mq4yBSdk9 .fgjIu_VActhOkP {
  padding-left: 0;
  font-weight: bold;
}
.Dfo16Mq4yBSdk9 .qaNp7OXRNT1Sud {
  padding-right: 0;
  text-align: right;
}
.jt2Q_YdyH6rUVS {
  text-transform: capitalize;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Dfo16Mq4yBSdk9`,
	"label": `fgjIu_VActhOkP`,
	"value": `qaNp7OXRNT1Sud`,
	"capitalize": `jt2Q_YdyH6rUVS`
};
export default ___CSS_LOADER_EXPORT___;
