export const BODY_DEFAULT_HEADER_CLASS = 'body-default-header';
export const CUSTOM_BOARD_BACKGROUND_CLASS = 'body-custom-board-background';
export const CUSTOM_BOARD_BACKGROUND_TILED_CLASS =
  'body-custom-board-background-tiled';
export const DARK_BOARD_BACKGROUND_CLASS = 'body-dark-board-background';
export const GRADIENT_BOARD_BACKGROUND_CLASS = 'body-gradient-board-background';
export const LIGHT_BOARD_BACKGROUND_CLASS = 'body-light-board-background';
export const STATIC_COLOR_BACKGROUND_CLASS =
  'body-static-color-board-background';

export const BOARD_VIEW_BACKGROUND_CLASSES = [
  BODY_DEFAULT_HEADER_CLASS,
  CUSTOM_BOARD_BACKGROUND_CLASS,
  CUSTOM_BOARD_BACKGROUND_TILED_CLASS,
  DARK_BOARD_BACKGROUND_CLASS,
  GRADIENT_BOARD_BACKGROUND_CLASS,
  LIGHT_BOARD_BACKGROUND_CLASS,
  STATIC_COLOR_BACKGROUND_CLASS,
];
