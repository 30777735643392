// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eTsMPUh1obd7s0 {
  grid-area: cvv;
}
.LehmeRF5tAV7XX {
  margin: 0 0 0 4px;
  padding: 0;
}
.LehmeRF5tAV7XX:focus {
  outline: 2px solid var(--ds-border-focused, #388bff);
}
.D5QQQJbmPfmHOZ {
  margin-bottom: 20px;
}
.D5QQQJbmPfmHOZ:last-child {
  margin: 0;
}
.D5QQQJbmPfmHOZ h4 {
  font-size: 14px;
}
.D5QQQJbmPfmHOZ p {
  font-size: 12px;
}
.D5QQQJbmPfmHOZ .aaHA3o8jCNkhVE {
  max-width: 100%;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `eTsMPUh1obd7s0`,
	"help": `LehmeRF5tAV7XX`,
	"cardExample": `D5QQQJbmPfmHOZ`,
	"preview": `aaHA3o8jCNkhVE`
};
export default ___CSS_LOADER_EXPORT___;
