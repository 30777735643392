import { useEffect } from 'react';

import { featureFlagClient } from '@trello/feature-flag-client';
import { intl } from '@trello/i18n';

import { Alerts } from 'app/scripts/views/lib/Alerts';

const onDisconnectActiveClients = (blockPollingRequests?: boolean) => {
  if (blockPollingRequests) {
    Alerts.showLiteralText(
      intl.formatMessage({
        id: 'somethings wrong.disconnected-from-trello',
        defaultMessage:
          'You have been disconnected from Trello. Please reload to reconnect.',
        description: 'Error message when disconnected from Trello.',
      }),
      'warning',
      'alert',
    );
  }
};

export const useDisconnectAlert = () => {
  useEffect(() => {
    featureFlagClient.on(
      'fep.disconnect_active_clients',
      false,
      onDisconnectActiveClients,
    );
    return () => {
      featureFlagClient.off(
        'fep.disconnect_active_clients',
        onDisconnectActiveClients,
      );
    };
  }, []);
};
