// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pwLA_DnwUq5xZT {
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}
.CpyGgjAzUkQDno {
  position: relative;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
  white-space: nowrap;
  background-color: var(--ds-background-input, #ffffff);
  box-shadow: inset 0 0 0 1px var(--ds-border-input, #091e4224);
}
.a4F8ecZELDNnaS {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .a4F8ecZELDNnaS {
  outline-color: -webkit-focus-ring-color;
}
.a4F8ecZELDNnaS .mac.firefox {
  outline-color: AccentColor;
}
.h5iifI9kUTr9qz {
  box-shadow: inset 0 0 0 1px var(--ds-border-danger, #e34935);
}
.VhaiZhQslxcjfC {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  opacity: 0;
}
.VhaiZhQslxcjfC svg {
  fill: transparent;
}
.bNtV9rWU0rn46W {
  display: inline-flex;
  position: relative;
  align-items: center;
  padding-top: 6px;
  padding-right: 8px;
  padding-bottom: 6px;
}
.faR06patL_ldVW {
  margin-left: 8px;
}
.x__X83c1QM1uFb:hover .CpyGgjAzUkQDno {
  background-color: var(--ds-background-input-hovered, #f7f8f9);
  box-shadow: inset 0 0 0 1px var(--ds-border-input, #091e4224);
}
.x__X83c1QM1uFb:hover .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno {
  background-color: var(--ds-background-input-hovered, #f7f8f9);
  box-shadow: inset 0 0 0 1px var(--ds-border-input, #091e4224);
  background-color: var(--ds-background-selected-bold-hovered, #0055cc);
  box-shadow: inset 0 0 0 1px var(--ds-border-selected, #0c66e4);
}
.x__X83c1QM1uFb:active .CpyGgjAzUkQDno,
.x__X83c1QM1uFb:active .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno {
  background-color: var(--ds-background-input-pressed, #ffffff);
  box-shadow: inset 0 0 0 1px var(--ds-border-focused, #388bff);
}
.x__X83c1QM1uFb:active .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno .VhaiZhQslxcjfC {
  width: 16px;
  height: 16px;
  opacity: 1;
}
.x__X83c1QM1uFb:active .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno .VhaiZhQslxcjfC svg {
  fill: var(--ds-icon-selected, #0c66e4);
}
.pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno,
.P98k_n1VEYvMVQ {
  background-color: var(--ds-background-selected-bold, #0c66e4);
  box-shadow: inset 0 0 0 1px var(--ds-border-selected, #0c66e4);
}
.pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno .VhaiZhQslxcjfC,
.P98k_n1VEYvMVQ .VhaiZhQslxcjfC {
  width: 16px;
  height: 16px;
  opacity: 1;
}
.pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno .VhaiZhQslxcjfC svg,
.P98k_n1VEYvMVQ .VhaiZhQslxcjfC svg {
  fill: var(--ds-icon-inverse, #ffffff);
}
.e3geeqSQ2Wfboe,
.e3geeqSQ2Wfboe:hover,
.e3geeqSQ2Wfboe:active {
  cursor: not-allowed;
}
.e3geeqSQ2Wfboe .CpyGgjAzUkQDno,
.e3geeqSQ2Wfboe:hover .CpyGgjAzUkQDno,
.e3geeqSQ2Wfboe:active .CpyGgjAzUkQDno {
  pointer-events: none;
  background-color: var(--ds-background-disabled, #091e4208);
  box-shadow: inset 0 0 0 1px var(--ds-border-disabled, #091e420f);
}
.e3geeqSQ2Wfboe .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno,
.e3geeqSQ2Wfboe:hover .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno,
.e3geeqSQ2Wfboe:active .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno {
  pointer-events: none;
  background-color: var(--ds-background-disabled, #091e4208);
  box-shadow: inset 0 0 0 1px var(--ds-border-disabled, #091e420f);
}
.e3geeqSQ2Wfboe .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno .VhaiZhQslxcjfC,
.e3geeqSQ2Wfboe:hover .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno .VhaiZhQslxcjfC,
.e3geeqSQ2Wfboe:active .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno .VhaiZhQslxcjfC {
  width: 16px;
  height: 16px;
  opacity: 1;
}
.e3geeqSQ2Wfboe .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno .VhaiZhQslxcjfC svg,
.e3geeqSQ2Wfboe:hover .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno .VhaiZhQslxcjfC svg,
.e3geeqSQ2Wfboe:active .pwLA_DnwUq5xZT:checked + .CpyGgjAzUkQDno .VhaiZhQslxcjfC svg {
  fill: var(--ds-icon-disabled, #091e424f);
}
.x__X83c1QM1uFb.sBnZV7XQzislKZ .CpyGgjAzUkQDno {
  background-color: var(--ds-background-selected-bold, #0c66e4);
  box-shadow: inset 0 0 0 1px var(--ds-border-selected, #0c66e4);
}
.x__X83c1QM1uFb.sBnZV7XQzislKZ .CpyGgjAzUkQDno .VhaiZhQslxcjfC {
  width: 16px;
  height: 16px;
  opacity: 1;
}
.x__X83c1QM1uFb.sBnZV7XQzislKZ .CpyGgjAzUkQDno .VhaiZhQslxcjfC svg {
  fill: var(--ds-icon-inverse, #ffffff);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nch-checkbox__input": `pwLA_DnwUq5xZT`,
	"nch-checkbox__box": `CpyGgjAzUkQDno`,
	"nch-checkbox__box--focusVisible": `a4F8ecZELDNnaS`,
	"nch-checkbox__box--invalid": `h5iifI9kUTr9qz`,
	"nch-checkbox__checkIcon": `VhaiZhQslxcjfC`,
	"nch-checkbox__label": `bNtV9rWU0rn46W`,
	"nch-checkbox__labelContent": `faR06patL_ldVW`,
	"nch-checkbox": `x__X83c1QM1uFb`,
	"nch-checkbox__checkedBox": `P98k_n1VEYvMVQ`,
	"nch-checkbox--disabled": `e3geeqSQ2Wfboe`,
	"nch-checkbox--indeterminate": `sBnZV7XQzislKZ`
};
export default ___CSS_LOADER_EXPORT___;
