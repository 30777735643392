/**
 * Determines whether the given value is a plain object.
 * Taken from `is-plain-object.coffee` originally.
 */
export function isPlainObject<Value extends object>(
  value: unknown,
): value is Value {
  if (!value || typeof value !== 'object') {
    return false;
  }

  return [null, Object.prototype].includes(Object.getPrototypeOf(value));
}
