import type { FunctionComponent } from 'react';

import { forTemplate } from '@trello/legacy-i18n';
import { WarningIcon } from '@trello/nachos/icons/warning';
import { token } from '@trello/theme';

import styles from './ChunkLoadErrorMessage.less';

const format = forTemplate('error');

export const ChunkLoadErrorMessage: FunctionComponent = () => {
  return (
    <div className={styles.chunkLoadErrorMessage}>
      <div className={styles.row}>
        <WarningIcon color={token('color.icon.danger', '#C9372C')} />
        <div>
          <p>{format('there-was-an-error-loading')}</p>
          <a href={location.href}>{format('refresh-page')}</a>
        </div>
      </div>
    </div>
  );
};
