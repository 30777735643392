import { useEffect, useLayoutEffect } from 'react';

import { setGlobalTheme as setThemeHtmlAttrs } from '@atlaskit/tokens';
import { Analytics } from '@trello/atlassian-analytics';

import { globalThemeState } from './globalThemeState';
import { setGlobalTheme } from './setGlobalTheme';
import type { ColorMode } from './theme.types';
import { useGlobalTheme } from './useGlobalTheme';

const VALID_COLOR_MODES: ColorMode[] = ['light', 'dark', 'auto'];

/**
 * Sets the `data-color-mode` attribute on the :html root when the appropriate
 * feature flag is enabled.
 */
export const useGlobalThemeUpdater = () => {
  const { colorMode } = useGlobalTheme();

  useLayoutEffect(() => {
    // Prevent users from viewing Trello with an invalid theme.
    // This will essentially serve as a mini-heartbeat to ensure that users are
    // correctly upgraded to a theme. We can remove it once we find that this is
    // no longer triggered.
    if (!VALID_COLOR_MODES.includes(colorMode)) {
      setGlobalTheme('auto');
      Analytics.sendTrackEvent({
        actionSubject: 'theme',
        action: 'converted',
        source: '@trello/theme',
      });
      return;
    }

    setThemeHtmlAttrs({ colorMode });
  }, [colorMode]);

  /**
   * With the changes to the @atlaskit/tokens API made for version 1.0.0,
   * we no longer have explicit access to the effective color mode in the app.
   * This shim is meant to help us retain access to the effective color mode
   * directly by updating our theme state to maintain a direct reference to the
   * actual color mode, i.e. `light`, `dark`, or `null`.
   */
  useEffect(() => {
    if (colorMode !== 'auto') {
      return;
    }

    const mql = window.matchMedia('(prefers-color-scheme: dark)');
    function setEffectiveColorMode({ matches }: { matches: boolean }) {
      const effectiveColorMode = matches ? 'dark' : 'light';
      globalThemeState.setValue({ effectiveColorMode });
    }

    setEffectiveColorMode({ matches: mql.matches });
    try {
      mql.addEventListener('change', setEffectiveColorMode);
    } catch (_) {
      // Safari versions < 14 don't support addEventListener yet.
      // Safari 14 is our minimum required version, but this would throw on page
      // load for unsupported versions, so this is just here for Sentry.
      mql.addListener(setEffectiveColorMode);
    }

    return () => mql.removeEventListener('change', setEffectiveColorMode);
  }, [colorMode]);
};
