import { createFragmentRegistry } from '@apollo/client/cache';

import { BoardListsContextCardFragmentDoc } from './BoardListsContextCardFragment.generated';
import { BoardListsContextListFragmentDoc } from './BoardListsContextListFragment.generated';
import { CheckItemFullFragmentDoc } from './CheckItemFullFragment.generated';
import { CurrentBoardFullCardFragmentDoc } from './CurrentBoardFullCardFragment.generated';
import { CurrentBoardFullListFragmentDoc } from './CurrentBoardFullListFragment.generated';

// eslint-disable-next-line @trello/no-module-logic
export const fragmentRegistry = createFragmentRegistry(
  BoardListsContextCardFragmentDoc,
  BoardListsContextListFragmentDoc,
  CheckItemFullFragmentDoc,
  CurrentBoardFullCardFragmentDoc,
  CurrentBoardFullListFragmentDoc,
);
