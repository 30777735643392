// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rX4pAv5sWHFNjp {
  color: var(--ds-text, #172b4d);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-display: swap;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  outline: 0;
  background-color: var(--ds-surface-overlay, #ffffff);
  box-shadow: var(--ds-shadow-overlay, 0px 8px 12px #091e4226, 0px 0px 1px #091e424f);
}
.MqQlviJ8LO60NB {
  display: grid;
  position: relative;
  grid-template-columns: 32px 1fr 32px;
  align-items: center;
  padding: 4px 8px;
  text-align: center;
}
.MqQlviJ8LO60NB.ehJjRqKTyUZYML {
  display: flex;
  position: static;
  box-sizing: border-box;
  grid: none;
  align-items: center;
  min-height: 40px;
  max-height: 49px;
  margin: 0 12px 8px;
  padding: 8px 0;
  border-bottom: 1px solid var(--ds-border, #091e4224);
}
.TzntopStGOcVjM {
  display: block;
  position: relative;
  grid-column: 1 / span 3;
  grid-row: 1;
  height: 40px;
  margin: 0;
  padding: 0 32px;
  overflow: hidden;
  color: var(--ds-text-subtle, #44546f);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.003em;
  line-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.TzntopStGOcVjM.ehJjRqKTyUZYML {
  display: -webkit-box;
  flex: 1;
  height: auto;
  padding: 0 32px;
  overflow: hidden;
  border: 0;
  line-height: initial;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.q2PzD_Dkq1FVX3 {
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0);
  padding: 12px;
  overflow-x: hidden;
  overflow-y: auto;
}
.q2PzD_Dkq1FVX3::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.q2PzD_Dkq1FVX3::-webkit-scrollbar-button:start:decrement,
.q2PzD_Dkq1FVX3::-webkit-scrollbar-button:end:increment {
  display: none;
  background: transparent;
}
.q2PzD_Dkq1FVX3::-webkit-scrollbar-track-piece {
  background: var(--ds-background-neutral, #091e420f);
}
.q2PzD_Dkq1FVX3::-webkit-scrollbar-track-piece:vertical:start {
  border-radius: 4px 4px 0 0;
}
.q2PzD_Dkq1FVX3::-webkit-scrollbar-track-piece:vertical:end {
  border-radius: 0 0 4px 4px;
}
.q2PzD_Dkq1FVX3::-webkit-scrollbar-track-piece:horizontal:start {
  border-radius: 4px 0 0 4px;
}
.q2PzD_Dkq1FVX3::-webkit-scrollbar-track-piece:horizontal:end {
  border-radius: 0 4px 4px 0;
}
.q2PzD_Dkq1FVX3::-webkit-scrollbar-thumb:vertical,
.q2PzD_Dkq1FVX3::-webkit-scrollbar-thumb:horizontal {
  display: block;
  height: 48px;
  border-radius: 4px;
  background: var(--ds-background-neutral-hovered, #091e4224);
}
.q2PzD_Dkq1FVX3:has(.popover-section-animating) {
  overflow-y: hidden;
}
.q2PzD_Dkq1FVX3:focus {
  outline: 0;
}
.q7bda2GIwjst4f {
  z-index: 2;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  color: var(--ds-icon-subtle, #626f86);
  cursor: pointer;
}
.q7bda2GIwjst4f:hover {
  color: var(--ds-icon, #44546f);
}
.zKzcyhLUVG0jDw {
  grid-column: 3;
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  color: var(--ds-icon-subtle, #626f86);
}
.zKzcyhLUVG0jDw:hover {
  background-color: var(--ds-background-neutral-hovered, #091e4224);
  color: var(--ds-icon, #44546f);
  text-decoration: none;
}
.zKzcyhLUVG0jDw:active {
  background-color: var(--ds-background-neutral-pressed, #091e424f);
  color: var(--ds-icon, #44546f);
  text-decoration: none;
}
.zKzcyhLUVG0jDw.ehJjRqKTyUZYML {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
}
.zKzcyhLUVG0jDw.Wf3NxQUCrVHW5X {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .zKzcyhLUVG0jDw.Wf3NxQUCrVHW5X {
  outline-color: -webkit-focus-ring-color;
}
.zKzcyhLUVG0jDw.Wf3NxQUCrVHW5X .mac.firefox {
  outline-color: AccentColor;
}
.Uz5Itgv85sjPQ_ {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  color: var(--ds-icon-subtle, #626f86);
}
.Uz5Itgv85sjPQ_:hover {
  background-color: var(--ds-background-neutral-hovered, #091e4224);
  color: var(--ds-icon, #44546f);
  text-decoration: none;
}
.Uz5Itgv85sjPQ_:active {
  background-color: var(--ds-background-neutral-pressed, #091e424f);
  color: var(--ds-icon, #44546f);
  text-decoration: none;
}
.Uz5Itgv85sjPQ_.Wf3NxQUCrVHW5X {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .Uz5Itgv85sjPQ_.Wf3NxQUCrVHW5X {
  outline-color: -webkit-focus-ring-color;
}
.Uz5Itgv85sjPQ_.Wf3NxQUCrVHW5X .mac.firefox {
  outline-color: AccentColor;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nch-popover": `rX4pAv5sWHFNjp`,
	"nch-popover__header": `MqQlviJ8LO60NB`,
	"UNSAFE_multilineTitle": `ehJjRqKTyUZYML`,
	"nch-popover__title": `TzntopStGOcVjM`,
	"nch-popover__content": `q2PzD_Dkq1FVX3`,
	"nch-popover__iconButton": `q7bda2GIwjst4f`,
	"nch-popover__closeButton": `zKzcyhLUVG0jDw`,
	"hasFocusRing": `Wf3NxQUCrVHW5X`,
	"nch-popover__backButton": `Uz5Itgv85sjPQ_`
};
export default ___CSS_LOADER_EXPORT___;
