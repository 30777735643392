// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JWYFIEsqOP41D7 {
  width: calc(100vw - 48px);
  min-width: 375px;
  max-width: 600px;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--ds-surface, #ffffff);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PremiumTrialPaymentModal": `JWYFIEsqOP41D7`
};
export default ___CSS_LOADER_EXPORT___;
