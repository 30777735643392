import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';

/**
 * See: https://blog.maisie.ink/react-ref-autofocus/
 */
export function useAutoFocusRef(
  ref: RefObject<HTMLElement>,
  delay: number = 0,
): void {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      ref.current?.focus();
      timeoutRef.current = null;
    }, delay);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [ref, delay]);
}
