// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BLERsaAp5zCjQ2 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-display: swap;
  display: block;
  margin-bottom: var(--ds-space-050, 2px);
  color: var(--ds-text, #172b4d);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.yMPj1pPmPFjeI1 input,
.yMPj1pPmPFjeI1 input:focus {
  box-shadow: none;
}
.LMMY5_Ly4eJLT2 {
  margin: 0;
  padding: 0;
}
.LMMY5_Ly4eJLT2 li:hover {
  background: none;
}
.rOJoKuMD3duvxf.y_qVsuPdfuJj_p {
  padding: 0;
}
.nhJBX8y7xuNKqG {
  background-color: var(--ds-background-disabled, #091e4208);
  color: var(--ds-text-disabled, #091e424f);
  pointer-events: none;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `BLERsaAp5zCjQ2`,
	"control": `yMPj1pPmPFjeI1`,
	"menu": `LMMY5_Ly4eJLT2`,
	"option": `rOJoKuMD3duvxf`,
	"noPadding": `y_qVsuPdfuJj_p`,
	"disabled": `nhJBX8y7xuNKqG`
};
export default ___CSS_LOADER_EXPORT___;
