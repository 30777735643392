import { createIntl, createIntlCache } from 'react-intl';

import type { TrelloWindow } from '@trello/window-types';
declare const window: TrelloWindow;

// eslint-disable-next-line @trello/no-module-logic
const cache = createIntlCache();
// eslint-disable-next-line @trello/no-module-logic
export const intlCache = createIntl(
  {
    locale: window.locale,
    messages: window.__locale,
  },
  cache,
);
