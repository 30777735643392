import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import classNames from 'classnames';

import type { ErrorHandlerProps } from '@trello/error-boundaries';
import { ErrorDetails } from '@trello/error-boundaries';
import { forTemplate } from '@trello/legacy-i18n';
import { token } from '@trello/theme';

import { HeaderSkeleton } from 'app/src/components/HeaderSkeleton';

import styles from './GlobalErrorHandler.less';

// eslint-disable-next-line @trello/assets-alongside-implementation
import tacoSleepSvg from 'resources/images/taco-sleep.svg';

const format = forTemplate('error');

export const GlobalErrorHandler: FunctionComponent<ErrorHandlerProps> = ({
  caughtError,
}) => {
  useEffect(() => {
    const trelloRoot = document.getElementById('trello-root');
    if (trelloRoot) {
      // Change the background color back to "inherit" to avoid contrast
      // problems on boards with a background color.
      trelloRoot.style.backgroundColor = 'inherit';
      trelloRoot.style.backgroundImage = 'inherit';
    }
  });

  return (
    <>
      <HeaderSkeleton backgroundColor={token('color.skeleton', '#091E420F')} />
      <div className={classNames(styles.errorMessage)}>
        <img alt="Taco" src={tacoSleepSvg} />
        <h1>{format('global-unhandled')}</h1>
        <p>{format('reload-call-to-action')}</p>
        <p>{format('contact-us')}</p>
        <ErrorDetails caughtError={caughtError} />
      </div>
    </>
  );
};
