import { useEffect, useState } from 'react';

export const usePageVisibilityListener = () => {
  const [isPageVisible, setisPageVisible] = useState(
    document.visibilityState === 'visible',
  );
  useEffect(() => {
    const abortController = new AbortController();
    document.addEventListener(
      'visibilitychange',
      () => {
        setisPageVisible(document.visibilityState === 'visible');
      },
      {
        signal: abortController.signal,
      },
    );
    return () => {
      abortController.abort();
    };
  }, []);

  return { isPageVisible };
};
