/* eslint-disable import/no-default-export */
import {
  createPortal,
  findDOMNode,
  render,
  unmountComponentAtNode,
} from './src/react-dom-wrapper';

export {
  createPortal,
  findDOMNode,
  render,
  unmountComponentAtNode,
} from './src/react-dom-wrapper';

/** @deprecated Please use `renderReactRoot` */
export default {
  /** @deprecated Please use `renderReactRoot` */
  render,
  /** @deprecated */
  unmountComponentAtNode,
  /** @deprecated */
  findDOMNode,
  /** @deprecated */
  createPortal,
};
