// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BR28axLiP6shHp {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 8px;
}
.Il2EVCJZwBC9KF {
  font-size: 12px;
  font-weight: normal;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `BR28axLiP6shHp`,
	"toggle": `Il2EVCJZwBC9KF`
};
export default ___CSS_LOADER_EXPORT___;
