/* eslint-disable @trello/disallow-filenames */
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectElementContents = function (el: any) {
  let range;
  // @ts-expect-error TS(2339): Property 'createTextRange' does not exist on type ... Remove this comment to see the full error message
  if (document.body.createTextRange) {
    // @ts-expect-error TS(2339): Property 'createTextRange' does not exist on type ... Remove this comment to see the full error message
    range = document.body.createTextRange();
    range.moveToElementText(el);
    range.select();
  } else {
    const selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(el);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    selection.removeAllRanges();
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    selection.addRange(range);
  }
};
