// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mWC2xT8NP_mW2g {
  height: 32px;
  margin-right: 4px;
  padding-right: 10px;
  line-height: 32px;
  white-space: nowrap;
}
/**
 * @override
 * When popover menu is open, override the DynamicButton styles
 * to hold it in the active color state.
 */
.mWC2xT8NP_mW2g.FrcabSLDUsJ9lS {
  color: var(--dynamic-text);
  background-color: var(--dynamic-button-pressed);
  color: var(--dynamic-button-pressed-text);
}
.mWC2xT8NP_mW2g.FrcabSLDUsJ9lS svg circle {
  stroke: var(--dynamic-icon);
}
.mWC2xT8NP_mW2g.FrcabSLDUsJ9lS:hover {
  color: var(--dynamic-text);
  background-color: var(--dynamic-button-pressed-hovered);
  color: var(--dynamic-button-pressed-text);
}
.mWC2xT8NP_mW2g.FrcabSLDUsJ9lS:hover svg circle {
  stroke: var(--dynamic-icon);
}
.kpv7OitsgQTIxo {
  margin-right: 2px;
}
.HOIZxjYHun9juo {
  min-width: 16px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `mWC2xT8NP_mW2g`,
	"buttonOpen": `FrcabSLDUsJ9lS`,
	"buttonText": `kpv7OitsgQTIxo`,
	"buttonIcon": `HOIZxjYHun9juo`
};
export default ___CSS_LOADER_EXPORT___;
