import type {
  DocumentNode,
  FieldNode,
  OperationDefinitionNode,
  SelectionSetNode,
  VariableDefinitionNode,
} from 'graphql';
import { Kind, OperationTypeNode } from 'graphql';

/**
 * Creates a selection set from object. This is the eqivalent
 * of what is returned from gql`query {}` where it includes a
 * selection set of fields based on the object provided
 */
const selectionSetFromObj = (obj: unknown): SelectionSetNode | null => {
  if (
    typeof obj === 'number' ||
    typeof obj === 'boolean' ||
    typeof obj === 'string' ||
    typeof obj === 'undefined' ||
    obj === null
  ) {
    // No selection set here
    return null;
  }

  if (Array.isArray(obj)) {
    // GraphQL queries don't include arrays
    return selectionSetFromObj(obj[0]);
  }

  // Now we know it's an object
  const selections: FieldNode[] = [];

  Object.keys(obj as { [key: string]: unknown }).forEach((key) => {
    const nestedSelSet: SelectionSetNode | null = selectionSetFromObj(
      (obj as { [key: string]: unknown })[key],
    );

    const field: FieldNode = {
      kind: Kind.FIELD,
      name: {
        kind: Kind.NAME,
        value: key,
      },
      selectionSet: nestedSelSet || undefined,
    };
    selections.push(field);
  });

  const selectionSet: SelectionSetNode = {
    kind: Kind.SELECTION_SET,
    selections,
  };

  return selectionSet;
};

export const queryFromPojo = (
  obj: unknown,
  variables?: { id: string },
): DocumentNode => {
  const selectionSet = selectionSetFromObj(obj) || {
    kind: Kind.SELECTION_SET,
    selections: [],
  };

  const variableDefinitions: VariableDefinitionNode[] = Object.keys(
    variables || {},
  ).map(() => ({
    directives: [],
    kind: Kind.VARIABLE_DEFINITION,
    type: {
      kind: Kind.NON_NULL_TYPE,
      type: {
        kind: Kind.NAMED_TYPE,
        name: {
          kind: Kind.NAME,
          value: 'ID',
        },
      },
    },
    variable: {
      kind: Kind.VARIABLE,
      name: {
        kind: Kind.NAME,
        value: 'id',
      },
    },
  }));

  if (selectionSet.selections[0]) {
    // @ts-expect-error
    selectionSet.selections[0].arguments = [
      {
        kind: Kind.ARGUMENT,
        name: {
          kind: Kind.NAME,
          value: 'id',
        },
        value: {
          kind: Kind.VARIABLE,
          name: {
            kind: Kind.NAME,
            value: 'id',
          },
        },
      },
    ];
  }

  const op: OperationDefinitionNode = {
    kind: Kind.OPERATION_DEFINITION,
    operation: OperationTypeNode.QUERY,
    name: {
      kind: Kind.NAME,
      value: 'GeneratedClientQuery',
    },
    selectionSet,
    variableDefinitions,
  };

  const out: DocumentNode = {
    kind: Kind.DOCUMENT,
    definitions: [op],
  };

  return out;
};
