/* eslint-disable no-console */
import { v4 as uuidv4 } from 'uuid';

export class FeatureFlagWatcher {
  private featuresContext: Record<string, string> = {};
  private activeFeatures = new Set<string>();
  private subscribers: Record<string, (list: string[]) => void> = {};

  get activeFeaturesList() {
    return [...this.activeFeatures.values()];
  }

  logFeatureInfo(featureKey: string) {
    const stack = this.getFeatureContext(featureKey);
    console.log('\n');
    console.log('%cFeature key:', 'color:Blue', featureKey);
    console.group('%cCall stack:', 'color:Blue');
    console.log(stack);
    console.groupEnd();
  }

  getFeatureContext(featureKey: string) {
    return this.featuresContext[featureKey];
  }

  assignFeatureContext(featureKey: string) {
    const callStack = Error().stack;
    if (!this.featuresContext[featureKey]) {
      this.featuresContext[featureKey] = `${callStack}`
        .split('\n')
        .slice(3)
        .filter((text) => !text.includes('eval'))
        .join('\n');
    }
  }

  trackFeature(featureKey: string) {
    this.assignFeatureContext(featureKey);

    this.activeFeatures.has(featureKey) &&
      this.activeFeatures.delete(featureKey);
    this.activeFeatures.add(featureKey);

    this.notifySubscribers([...this.activeFeatures.values()]);
  }

  resetActiveFeatures() {
    this.activeFeatures = new Set();
  }

  subscribeToFeatureList(callback: (list: string[]) => void) {
    const id = uuidv4();
    this.subscribers[id] = callback;

    return {
      unsubscribe: () => {
        delete this.subscribers[id];
      },
    };
  }

  private notifySubscribers(data: string[]) {
    Object.values(this.subscribers).forEach((callback) => callback(data));
  }
}

// eslint-disable-next-line @trello/no-module-logic
export const featureFlagWatcher = new FeatureFlagWatcher();
