class Entitlement {
  isFree(offering?: string | null): boolean {
    return offering === 'trello.free';
  }

  isStandard(offering?: string | null): boolean {
    return offering === 'trello.standard';
  }

  isPremium(offering?: string | null): boolean {
    return (
      offering === 'trello.business_class' ||
      offering === 'trello.premium_po' ||
      offering === 'trello.premium'
    );
  }

  isEnterprise(offering?: string | null): boolean {
    return (
      offering === 'trello.enterprise' ||
      offering === 'trello.enterprise_without_sso'
    );
  }
}

// eslint-disable-next-line @trello/no-module-logic
export const Entitlements = new Entitlement();
