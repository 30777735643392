import type { BillingDates, ExpirationDates, ScheduledChange } from './types';

/**
 * Get the next billing date for the given paidAccount
 * object. Looks up the date string for the product
 * and converts it to a Date. Returns null if not
 * applicable
 */
export const getNextBillingDate = (
  paidAccount?: {
    products: number[];
    billingDates?: BillingDates | null;
  } | null,
): Date | null => {
  const product = paidAccount?.products?.[0];
  const billingDates = paidAccount?.billingDates;
  if (
    !product ||
    !billingDates ||
    !Object.prototype.hasOwnProperty.call(billingDates, product)
  ) {
    return null;
  }
  return new Date(billingDates[product]);
};

/**
 * Get the expiration date for a give paidAccount
 * object. Looks up the date string for the product
 * and converts it to a Date. Returns null if not
 * applicable
 */
export const getExpirationDate = (
  paidAccount?: {
    products: number[];
    expirationDates?: ExpirationDates | null;
  } | null,
): Date | null => {
  const product = paidAccount?.products?.[0];
  const expirationDates = paidAccount?.expirationDates;
  if (
    !product ||
    !expirationDates ||
    !Object.prototype.hasOwnProperty.call(expirationDates, product)
  ) {
    return null;
  }
  return new Date(expirationDates[product]);
};

/**
 * Check if a subscription is pending cancellation. Prefer
 * using `scheduledChange` as it distinguishes cancellations
 * from downgrades. If it is not present, fall back to
 * seeing if there is an expiration date on the product
 * subscription.
 */
export const isPendingCancellation = (
  paidAccount?: {
    products: number[];
    expirationDates: ExpirationDates;
    scheduledChange?: ScheduledChange | null;
  } | null,
): boolean => {
  const { scheduledChange } = paidAccount ?? {};

  if (scheduledChange?.nextChangeTimestamp) {
    return !scheduledChange.ixSubscriptionProduct;
  }

  return !!getExpirationDate(paidAccount);
};
