// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fizbjUpLonjaqI {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `fizbjUpLonjaqI`
};
export default ___CSS_LOADER_EXPORT___;
