import type { FunctionComponent } from 'react';

import { forTemplate } from '@trello/legacy-i18n';

import styles from './PageError.less';

// eslint-disable-next-line @trello/assets-alongside-implementation
import tacoSleepSvg from 'resources/images/taco-sleep.svg';

const format = forTemplate('error');

export const PageError: FunctionComponent = () => (
  <div className={styles.errorMessage}>
    <img alt="Taco" src={tacoSleepSvg} />
    <h1>{format('global-unhandled')}</h1>
    <p>{format('reload-call-to-action')}</p>
    <p>{format('contact-us')}</p>
  </div>
);
