import Backbone from '@trello/backbone';

import type { BackboneHistoryNavigateOptions } from './Router.types';

interface BeforeNavigateCallbackArguments {
  path: string;
  options?: BackboneHistoryNavigateOptions;
  next: () => void;
}

type BeforeNavigateCallback = (
  callbackArguments: BeforeNavigateCallbackArguments,
) => void;

const beforeNavigateCallbacks: BeforeNavigateCallback[] = [];

/***
 * @deprecated This function should not be used. It was only introduced to remove circular dependencies.
 */
export const beforeNavigate = (callback: BeforeNavigateCallback) => {
  if (!beforeNavigateCallbacks.includes(callback)) {
    beforeNavigateCallbacks.push(callback);
  }
};

function runBeforeNavigateMiddleware(
  callbackList: BeforeNavigateCallback[],
  currentCallbackIndex: number,
  callbackArguments: Omit<BeforeNavigateCallbackArguments, 'next'>,
  done: () => void,
) {
  callbackList[currentCallbackIndex]({
    ...callbackArguments,
    next: callbackList[currentCallbackIndex + 1]
      ? () => {
          runBeforeNavigateMiddleware(
            callbackList,
            currentCallbackIndex + 1,
            callbackArguments,
            done,
          );
        }
      : done,
  });
}

const navigateWithBackbonePatch = (
  path: string,
  options?: BackboneHistoryNavigateOptions,
) => {
  Backbone.history.navigate(path, options);

  const currentPath = `${window.location.pathname}${window.location.search}${window.location.hash}`;

  let normalizedPath = path;
  if (path?.charAt(0) !== '/') {
    normalizedPath = `/${path}`;
  }

  if (currentPath !== normalizedPath) {
    /**
     * Backbone does not completely support query parameters. It's buggy. To solve this,
     * we do a manual replace here when backbone's doesn't work. A good example of when this
     * occurs is if you go to a route with query parameters on initial load, then trigger a navigate
     * to remove the query params.
     */
    window.history.replaceState(null, '', path);
  }
};

export const navigate = (
  path: string,
  options?: BackboneHistoryNavigateOptions,
) => {
  if (beforeNavigateCallbacks.length > 0) {
    runBeforeNavigateMiddleware(
      beforeNavigateCallbacks,
      0,
      {
        path,
        options,
      },
      () => {
        navigateWithBackbonePatch(path, options);
      },
    );
  } else {
    navigateWithBackbonePatch(path, options);
  }
};
