// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nkyput1WasEeS4 {
  margin-top: 20px;
  color: var(--ds-text-subtle, #44546f);
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  height: 20px;
  margin-top: 0;
  padding: 0 5px;
  border-radius: 2px;
  background-color: var(--ds-background-information-bold, #0c66e4);
  color: var(--ds-text-inverse, #ffffff);
  line-height: 20px;
  text-align: center;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `nkyput1WasEeS4`
};
export default ___CSS_LOADER_EXPORT___;
