// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aB6Ki0g6k0dmGn {
  color: var(--ds-text-subtle, #44546f);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
.GCXmNq1GDDap9g {
  padding-left: 2px;
  color: var(--ds-icon-danger, #e34935);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `aB6Ki0g6k0dmGn`,
	"required": `GCXmNq1GDDap9g`
};
export default ___CSS_LOADER_EXPORT___;
