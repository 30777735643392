import type { FunctionComponent, PropsWithChildren } from 'react';

import styles from './Surface.less';

export const Surface: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <div id="surface" className={styles.surface}>
      {children}
    </div>
  );
};
