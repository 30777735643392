import type { ComponentProps, FunctionComponent } from 'react';
import classNames from 'classnames';

import { RouterLink } from '@trello/router/router-link';

import styles from './HeaderButton.less';

export const HeaderLink: FunctionComponent<
  ComponentProps<typeof RouterLink>
> = ({ children, className, ...rest }) => (
  <RouterLink {...rest} className={classNames(styles.headerButton, className)}>
    {children}
  </RouterLink>
);
