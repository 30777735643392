// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.lsOhPsHuxEMYEb {
  box-sizing: border-box;
  border: none;
  outline: none;
  color: var(--ds-text, #172b4d);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-display: swap;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
  padding: 8px 12px;
  border-radius: 3px;
  background-color: var(--ds-background-input, #ffffff);
  box-shadow: inset 0 0 0 1px var(--ds-border-input, #091e4224);
  width: 100%;
  margin-bottom: 0;
}
input.lsOhPsHuxEMYEb::placeholder {
  color: var(--ds-text-subtlest, #626f86);
}
input.lsOhPsHuxEMYEb:read-only {
  background-color: var(--ds-background-disabled, #091e4208);
  box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
}
input.lsOhPsHuxEMYEb:hover {
  background-color: var(--ds-background-input-hovered, #f7f8f9);
  box-shadow: inset 0 0 0 1px var(--ds-border-input, #091e4224);
}
input.lsOhPsHuxEMYEb:focus {
  background-color: var(--ds-background-input, #ffffff);
  box-shadow: inset 0 0 0 2px var(--ds-border-focused, #388bff);
}
input.o0Tq65mN8ax4cC {
  padding-left: 32px;
}
input.F2JNpFsRxSfH_q {
  padding-right: 32px;
}
input.VkPAAkbpkKnPst,
input.VkPAAkbpkKnPst:hover {
  box-shadow: inset 0 0 0 1px var(--ds-border-danger, #e34935);
}
input.aXCorvBftOj0ko {
  background-color: transparent;
  box-shadow: none;
}
input.aXCorvBftOj0ko:read-only {
  background-color: transparent;
  box-shadow: none;
}
input.aXCorvBftOj0ko:hover {
  background-color: transparent;
  box-shadow: none;
}
input.aXCorvBftOj0ko:focus {
  background-color: transparent;
  box-shadow: none;
}
input.h_p9IpkujNLqy3 {
  background-color: var(--ds-background-neutral, #091e420f);
  box-shadow: none;
}
input.h_p9IpkujNLqy3:hover {
  background-color: var(--ds-background-neutral-hovered, #091e4224);
  box-shadow: none;
}
input.h_p9IpkujNLqy3:focus {
  background-color: var(--ds-background-input, #ffffff);
  box-shadow: inset 0 0 0 2px var(--ds-border-focused, #388bff);
}
input.QqW05x2XlRRmVO {
  height: 24px;
  padding: 4px;
  line-height: 24px;
}
input.lsOhPsHuxEMYEb:disabled,
input.k71J8Kz5WXElX7 {
  background-color: var(--ds-background-disabled, #091e4208);
  box-shadow: none;
  color: var(--ds-text-disabled, #091e424f);
  cursor: not-allowed;
  -webkit-user-select: none;
          user-select: none;
}
input.aXCorvBftOj0ko:disabled,
input.wUNl1z0TzFJ7m5.k71J8Kz5WXElX7,
input.aXCorvBftOj0ko:disabled:hover,
input.wUNl1z0TzFJ7m5.k71J8Kz5WXElX7:hover,
input.aXCorvBftOj0ko:disabled:active,
input.wUNl1z0TzFJ7m5.k71J8Kz5WXElX7:active {
  background-color: var(--ds-UNSAFE-transparent, transparent);
  box-shadow: none;
  color: var(--ds-text-subtle, #44546f);
  cursor: not-allowed;
  -webkit-user-select: none;
          user-select: none;
}
.bKPMIrxAJqgHwG {
  margin-left: 2px;
  color: var(--ds-icon-accent-red, #e34935);
}
label.fMvxkh4DHKJGnq {
  color: var(--ds-text-subtle, #44546f);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  flex-flow: column;
}
label.fMvxkh4DHKJGnq input {
  margin-top: 2px;
}
.Qd6trFm0Z32HJe {
  display: flex;
  position: relative;
  align-items: center;
}
.OJ95KNJy4oM6zI {
  position: absolute;
  left: 6px;
}
.sVZR38uDcPpmYh {
  position: absolute;
  right: 6px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nch-textfield__input": `lsOhPsHuxEMYEb`,
	"nch-textfield__input--withIconBefore": `o0Tq65mN8ax4cC`,
	"nch-textfield__input--withIconAfter": `F2JNpFsRxSfH_q`,
	"nch-textfield__input--invalid": `VkPAAkbpkKnPst`,
	"nch-textfield__input--subtle": `aXCorvBftOj0ko`,
	"nch-textfield__input--borderless": `h_p9IpkujNLqy3`,
	"nch-textfield__input--compact": `QqW05x2XlRRmVO`,
	"nch-textfield__input--disabled": `k71J8Kz5WXElX7`,
	"nch-textfield__input--subtleinput": `wUNl1z0TzFJ7m5`,
	"nch-textfield__requiredMarker": `bKPMIrxAJqgHwG`,
	"nch-textfield__label": `fMvxkh4DHKJGnq`,
	"nch-textfield__iconContainer": `Qd6trFm0Z32HJe`,
	"nch-textfield__icon--before": `OJ95KNJy4oM6zI`,
	"nch-textfield__icon--after": `sVZR38uDcPpmYh`
};
export default ___CSS_LOADER_EXPORT___;
