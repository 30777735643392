// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O1u5lZUUgXVadi {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  outline: none;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popoverBoundary": `O1u5lZUUgXVadi`
};
export default ___CSS_LOADER_EXPORT___;
