import { useMemo } from 'react';

import { getOrgPaidStatus } from '@trello/business-logic/organization';

import { useWorkspace } from './useWorkspace';
import { useWorkspaceStatusQuery } from './WorkspaceStatusQuery.generated';

type WorkspaceStatus = ReturnType<typeof getOrgPaidStatus> | 'NO_TEAM';

export const useWorkspaceStatus = (): WorkspaceStatus | null => {
  const { workspaceId } = useWorkspace();
  const { data, loading } = useWorkspaceStatusQuery({
    variables: { idOrganization: workspaceId || '' },
    skip: !workspaceId,
    waitOn: ['None'],
  });
  return useMemo(() => {
    if (loading) {
      return null;
    }
    return data?.organization ? getOrgPaidStatus(data.organization) : 'NO_TEAM';
  }, [loading, data?.organization]);
};
