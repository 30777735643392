import { useEffect } from 'react';

import { useFeatureGate } from '@trello/feature-gate-client';

const BLUR_CLASS = 'overlay-blur';

export const useDialogEffect = () => {
  const { value: isOverlayBlurEnabled } = useFeatureGate(
    'phoenix_fg_split_screen_nav',
  );

  useEffect(() => {
    const hasBlurClass = document.body.classList.contains(BLUR_CLASS);

    if (isOverlayBlurEnabled && !hasBlurClass) {
      document.body.classList.add(BLUR_CLASS);
    } else if (!isOverlayBlurEnabled && hasBlurClass) {
      document.body.classList.remove(BLUR_CLASS);
    }
  }, [isOverlayBlurEnabled]);
};
