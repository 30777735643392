import { useCallback, useMemo } from 'react';

import { useMemberId } from '@trello/authentication';

import { useAddOneTimeMessageDismissedMutation } from './AddOneTimeMessageDismissedMutation.generated';
import { useMyOneTimeMessagesDismissedFragment } from './MyOneTimeMessagesDismissedFragment.generated';

export const useOneTimeMessagesDismissed = () => {
  const memberId = useMemberId();
  const { data: member } = useMyOneTimeMessagesDismissedFragment({
    from: { id: memberId },
    optimistic: true,
  });

  const [addOneTimeMessageDismissed] = useAddOneTimeMessageDismissedMutation();

  const oneTimeMessagesDismissed = useMemo(
    () => member?.oneTimeMessagesDismissed ?? [],
    [member?.oneTimeMessagesDismissed],
  );

  const isOneTimeMessageDismissed = useCallback(
    (messageId: string) =>
      oneTimeMessagesDismissed.some((message) => message === messageId),
    [oneTimeMessagesDismissed],
  );

  const dismissOneTimeMessage = useCallback(
    async (
      messageId: string,
      options: { optimistic?: boolean } = { optimistic: true },
    ) => {
      if (isOneTimeMessageDismissed(messageId)) {
        return;
      }

      return addOneTimeMessageDismissed({
        variables: {
          memberId: memberId || 'me',
          messageId,
        },
        optimisticResponse: options?.optimistic
          ? {
              __typename: 'Mutation',
              addOneTimeMessagesDismissed: {
                id: memberId || 'me',
                oneTimeMessagesDismissed: oneTimeMessagesDismissed?.concat([
                  messageId,
                ]),
                __typename: 'Member',
              },
            }
          : undefined,
      });
    },
    [
      addOneTimeMessageDismissed,
      isOneTimeMessageDismissed,
      memberId,
      oneTimeMessagesDismissed,
    ],
  );

  return {
    oneTimeMessagesDismissed,
    dismissOneTimeMessage,
    isOneTimeMessageDismissed,
  };
};
