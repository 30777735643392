import { PersistentSharedState } from '@trello/shared-state';

import type { ColorMode, EffectiveColorMode } from './theme.types';

export interface GlobalThemeState {
  /**
   * The color mode used across the app, inclusive of 'auto'.
   * @default 'auto'
   */
  colorMode: ColorMode;
  /**
   * The effective color mode used across the app, exclusive of 'auto'.
   * @default 'light'
   */
  effectiveColorMode: EffectiveColorMode;
}

export const globalThemeState = new PersistentSharedState<GlobalThemeState>(
  { colorMode: 'auto', effectiveColorMode: 'light' },
  { storageKey: 'global-theme' },
);
