import type { FunctionComponent, ReactElement } from 'react';
import cx from 'classnames';

import AKIcon from '@atlaskit/icon';
import { token } from '@trello/theme';

import {
  ComponentSizeL as LARGE,
  ComponentSizeM as MEDIUM,
  ComponentSizeS as SMALL,
  ComponentSizeXs as XSMALL,
  GLOBAL_NAMESPACE_PREFIX,
  IconClassnameBase,
  IconDefaultColor,
} from '../../../tokens';
import type { IconSize } from './Icon.types';

import styles from './Icon.less';

export const IconClasses = {
  BASE: `${GLOBAL_NAMESPACE_PREFIX}${IconClassnameBase}`,
  xsmall: `${GLOBAL_NAMESPACE_PREFIX}${IconClassnameBase}--${XSMALL}`,
  small: `${GLOBAL_NAMESPACE_PREFIX}${IconClassnameBase}--${SMALL}`,
  medium: `${GLOBAL_NAMESPACE_PREFIX}${IconClassnameBase}--${MEDIUM}`,
  large: `${GLOBAL_NAMESPACE_PREFIX}${IconClassnameBase}--${LARGE}`,
  BLOCK: `${GLOBAL_NAMESPACE_PREFIX}${IconClassnameBase}--block`,
};

export interface IconProps {
  /**
   * The color that the Icon should be rendered as.
   * @default token('color.icon', '#42526E')
   */
  color?: string;
  /**
   * A string that is applied as an aria attribute on the icon. Usually it
   * matches up with the display name of the icon
   * @default ''
   */
  label?: string;
  /**
   * The name of the glyph which to render as the Icon. This must be provided.
   */
  glyph: () => ReactElement;
  /**
   * The size to render the icon.
   * @default "medium"
   */
  size?: IconSize;
  /**
   * A string that gets placed as a data attribute (data-testid) onto the
   * Icon wrapper so that our
   * smoketest can properly target and test the component
   * @default undefined
   */
  testId?: string;
  // Escape hatches
  /**
   * ⚠️ DO NOT USE THIS PROP UNLESS YOU REALLY REALLY HAVE TO.
   *
   * Places a class name on the Icon (more specifically, the svg itself). This
   * is placed in addition to the classes already placed on the Icon. This is
   * placed directly onto the SVG via the glyph templates that are
   * generated by IconGlyph.template.js
   *
   * Please refrain from using this unless absolutely necessary.
   * @default undefined
   */
  dangerous_className?: string;
  /**
   * The switch for the icon to be centered in the dedicated space with padding around it.
   * Designed for cases when icon is not inline.
   */
  block?: boolean;
}

export const Icon: FunctionComponent<IconProps> = (props) => {
  const {
    color,
    testId,
    size = MEDIUM,
    glyph: Glyph,
    label,
    dangerous_className,
    block,
  } = props;

  const iconClassName = cx(
    {
      [IconClasses.BASE]: true,
      [styles[IconClasses.BASE]]: true,
      [styles[IconClasses[size]]]: !!size,
      [styles[`${IconClasses[size]}-block`]]: block,
    },
    dangerous_className,
  );

  return (
    <span className={iconClassName}>
      <AKIcon
        testId={testId}
        secondaryColor="inherit"
        label={label || ''}
        // Note: When `color` is undefined, `currentColor` is used instead.
        // This is the AK default - and is totally valid. The fallback value is
        // used for historical parity, but we should consider reenabling
        // the currentColor default in the future.
        primaryColor={color || token('color.icon', IconDefaultColor)}
        glyph={() => <Glyph />}
      />
    </span>
  );
};
