import { differenceInMonths, isSameYear } from 'date-fns';

import {
  mediumDateFormatter,
  mediumDateWithoutYearFormatter,
} from './formatters';

export const formatHumanDate = (date: Date | string) => {
  const now = Date.now();

  const isAmbiguousMonth = Math.abs(differenceInMonths(now, date)) > 9;
  const shouldHideYear = isSameYear(now, date) && !isAmbiguousMonth;

  if (shouldHideYear) {
    return mediumDateWithoutYearFormatter.format(new Date(date));
  }
  return mediumDateFormatter.format(new Date(date));
};
