import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { trelloFetch } from '@trello/fetch';
import { parseNetworkError } from '@trello/graphql-error-handling';
import { getNetworkClient } from '@trello/network-client';
import { getCsrfRequestPayload } from '@trello/session-cookie/csrf';

import type { MutationCreateOrderArgs, QueryGetOrderArgs } from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import type { TrelloRestResolver } from '../types';

export const createTransactionAccount: TrelloRestResolver<void> = async (
  obj,
  args,
  context,
) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/atl/ccp/createTransactionAccount`);
  const response = await trelloFetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-Trello-Client-Version': context.clientAwareness.version,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const body = await response.json();
  return body;
};

export const createOrder: TrelloRestResolver<MutationCreateOrderArgs> = async (
  obj,
  { idTransactionAccount, metadata },
  context,
) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/atl/ccp/orders`);
  const response = await trelloFetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-Trello-Client-Version': context.clientAwareness.version,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionAccountId: idTransactionAccount,
      metadata,
      ...getCsrfRequestPayload(),
    }),
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const body = await response.json();
  return body;
};

export const getOrderResolver: TrelloRestResolver<QueryGetOrderArgs> = async (
  _,
  { idOrder, idTransactionAccount },
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/atl/ccp/orders/${idOrder}`);

  // poll orders endpoint until org has been created
  let body;
  while (!body?.organization) {
    const response = await trelloFetch(apiUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'X-Trello-Client-Version': context.clientAwareness.version,
        'X-transaction-account': idTransactionAccount,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      sendNetworkErrorEvent({
        url: apiUrl,
        response: await response.clone().text(),
        status: response.status,
        operationName: context.operationName,
      });
      throw await parseNetworkError(response);
    }
    body = await response.json();
  }
  return prepareDataForApolloCache(body, rootNode);
};
