// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frrHNIWnTojsww {
  background-color: var(--dynamic-button);
  color: var(--dynamic-text);
}
.frrHNIWnTojsww .rWCw3WNaQDtp6G [role='img'] > svg,
.frrHNIWnTojsww .nTVT4EIcAoFr87 [role='img'] > svg,
.frrHNIWnTojsww .vf2hEe_dVf3pQY [role='img'] > svg,
.frrHNIWnTojsww .rWCw3WNaQDtp6G svg[role='presentation'],
.frrHNIWnTojsww .nTVT4EIcAoFr87 svg[role='presentation'],
.frrHNIWnTojsww .vf2hEe_dVf3pQY svg[role='presentation'] {
  color: unset !important;
}
.frrHNIWnTojsww svg circle {
  stroke: var(--dynamic-icon);
}
.frrHNIWnTojsww:hover {
  background-color: var(--dynamic-button-hovered);
  color: var(--dynamic-text);
}
.frrHNIWnTojsww:hover svg circle {
  stroke: var(--dynamic-icon);
}
.frrHNIWnTojsww:active {
  color: var(--dynamic-text);
  background-color: var(--dynamic-button-pressed);
  color: var(--dynamic-button-pressed-text);
}
.frrHNIWnTojsww:active svg circle {
  stroke: var(--dynamic-icon);
}
.frrHNIWnTojsww.DX_JGmmXEWu3Ti {
  color: var(--dynamic-text);
  background-color: var(--dynamic-button-highlighted);
  color: var(--dynamic-button-highlighted-text);
}
.frrHNIWnTojsww.DX_JGmmXEWu3Ti svg circle {
  stroke: var(--dynamic-icon);
}
.frrHNIWnTojsww.DX_JGmmXEWu3Ti:hover {
  background: var(--dynamic-button-highlighted-hovered);
  color: var(--dynamic-button-highlighted-text);
}
.frrHNIWnTojsww.iLddpEi_wwDTij {
  color: var(--dynamic-text);
  background-color: var(--dynamic-button-primary);
  color: var(--dynamic-button-primary-text);
}
.frrHNIWnTojsww.iLddpEi_wwDTij svg circle {
  stroke: var(--dynamic-icon);
}
.frrHNIWnTojsww.iLddpEi_wwDTij:hover {
  background: var(--dynamic-button-primary-hovered);
  color: var(--dynamic-button-primary-text);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dynamicButton": `frrHNIWnTojsww`,
	"iconBefore": `rWCw3WNaQDtp6G`,
	"iconAfter": `nTVT4EIcAoFr87`,
	"iconOnly": `vf2hEe_dVf3pQY`,
	"highlighted": `DX_JGmmXEWu3Ti`,
	"primary": `iLddpEi_wwDTij`
};
export default ___CSS_LOADER_EXPORT___;
