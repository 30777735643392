import { sendErrorEvent } from '@trello/error-reporting';

import { useMemberContextDataQuery } from './MemberContextDataQuery.generated';

export const useMemberContextData = () => {
  const { data, error } = useMemberContextDataQuery({
    waitOn: ['MemberHeader'],
  });

  if (error)
    sendErrorEvent(error, {
      tags: { ownershipArea: 'trello-web-eng' },
    });

  return data;
};
